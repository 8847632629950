import { BrowserRouter } from 'react-router-dom';
import { useState } from 'react';
import RouterConfig from './routes/RouterConfig';
import { ConfigProvider } from 'antd-mobile';
import koKR from 'antd-mobile/es/locales/ko-KR';
import styled from 'styled-components';
import { ClientSocket } from 'components/socket/ClientSocket';
import { AccessPermissionPopup } from 'components/containers';
import { useAccessPermission } from 'hooks/useAccessPermission';
import { CommonProvider } from 'hooks/CommonContext';
import { isMobile } from 'utils/mobile';
import { AGENT } from 'constants/Mobile';


const Root = styled.div`
  /* height: 100vh; */
  background: #fff;
  height: 100%;
  max-width: 72rem;
  margin: auto;
  
`;

const checkAgentType = () => {
  if (isMobile.Android()) {
    if (window.pethome) return AGENT.ANDROID_APP
    else return AGENT.ANDROID_WEB
  } else if (isMobile.iOS()) {
    if (navigator.userAgent.match(/pethome.ios/i) !== null) return AGENT.IOS_APP
    else return AGENT.IOS_WEB
  } else {
    return AGENT.PC
  }
}
const checkMobile = () => {
  if (isMobile.Android() || isMobile.iOS()) {
    return true;
  } else {
    return false;
  }
}

function App() {
  const [common, setCommon] = useState({
    agentType: checkAgentType(),
    mobile: checkMobile(),
  });
  const { requestAccess } = useAccessPermission();

  const [accessModal, setAccessModal] = useState({
    isOpen: false,
    type: undefined,
    callback: undefined,
    errorCallback: undefined,
    closeCallback: undefined,
  })
  const fnHandleShowAccess = (callback, errorCallback, closeCallback=undefined, type) => {
    setAccessModal({ isOpen: true, callback, errorCallback, closeCallback, type})
  }
  const fnCloseShowAccess = () => {
    if (accessModal?.closeCallback) accessModal.closeCallback()
    setAccessModal({ isOpen: false })
  }
  const fnOkAccess = () => {
    const requestSuccess = requestAccess({ type: accessModal.type });
    if (requestSuccess && accessModal?.callback) accessModal.callback(requestAccess);
    else accessModal.errorCallback();
    setAccessModal({ isOpen: false })
    //fnCloseShowAccess();
  }


  return (
    <>
      <ConfigProvider
        locale={koKR}
        // theme={{
        //   token: {
        //     fontFamily: 'Jua', // 'Noto Sans KR',
        //   },
        // }}
      >
        <Root>
          <BrowserRouter>
            <CommonProvider>
              <RouterConfig common={common} showAccess={fnHandleShowAccess}/>
            </CommonProvider>
            <ClientSocket common={common}/>
            {/* 접근권한모달 */}
            <AccessPermissionPopup
                show={accessModal.isOpen}
                accessType={accessModal.type}
                onClose={fnCloseShowAccess}
                onOk={fnOkAccess}
            />
          </BrowserRouter>
        </Root>
      </ConfigProvider>
    </>

  );
}

export default App;
