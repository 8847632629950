import axios from 'axios';
import qs from 'qs';

const url = process.env.REACT_APP_API_BASEURL;

const client = axios.create({
  baseURL: `${url}`,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
  timeout: 360000,
});

// client.defaults.headers.common['Authorization'] = 'AUTH_TOKEN' // request header 변경 필요 시 사용
// client.defaults.headers.get['Accept'] = 'application/json' // request header 변경 필요 시 사용
client.defaults.paramsSerializer = params => {
  return qs.stringify(params);
}

client.interceptors.request.use(
  (config) => {
    config.params = config.params || {};

    const user = window.localStorage.getItem('persist:user');
    if (user && user !== 'null') {
      const userObj = JSON.parse(user);
      if (userObj?.token) config.headers.Authorization = userObj.token;
    }
    // config.params["api_key"] = "86243712afd0457f7ec4e7cc2ad4208b";
    // config.params["language"] = "en-US";
    return config;
  },
  (err) => {
    return Promise.reject(err);
  },
);

client.interceptors.response.use(
  (config) => {
    return config;
  },
  (error) => {
    console.log('error', error);
    return Promise.reject(error);
  },
);

export default client;
