import React from 'react';
import ReactDOM from 'react-dom/client';

import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import rootReducers from './store';
import rootSaga from './saga';

import App from './App';
import GlobalStyles from 'config/GlobalStyles';

import 'moment/locale/ko';
import './assets/css/main.css';
import './assets/scss/index.scss';
import { AuthProvider } from 'hooks/AuthContext';

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  rootReducers,
  composeWithDevTools(applyMiddleware(sagaMiddleware)),
);

sagaMiddleware.run(rootSaga);

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);
root.render(
  <AuthProvider>
    <Provider store={store}>
      {/* react-spring-bottom-sheet bug 방지위해 StrictMode 해제 */}
      {/* <React.StrictMode> */}
      <GlobalStyles />
      <App />
      {/* </React.StrictMode> */}
    </Provider>
  </AuthProvider>
);
