import { AutoCenter } from "antd-mobile"
import { BaseTextButton } from "components/elements"
import { BaseLayout } from "components/layouts"
import { BACK } from "constants"
import { useNavigate } from "react-router-dom"
import { URL_HOME } from "routes/RouteUrl"

export const NoMathPage = () => {
  const navigate = useNavigate();

  return (
    <BaseLayout>
      <div
        style={{
          width: '100%',
          height: '100%'
        }}  
      >
        <div
          style={{
            position: 'relative',
            top: '38%',
            //left: '39%',
          }}
        >
          <div className="vm center">
            <div
              style={{
                padding: '12px 0px'
              }}
            >
              <p
                style={{
                  fontSize: '2rem',
                  textAlign: 'center',
                  color: '#606060',
                  lineHeight: '3rem',
                }}      
              >앗! 페이지를 찾을 수 없어요.</p>
              <p
                style={{
                  fontSize: '1.6rem',
                  textAlign: 'center',
                  color: '#606060'
                }}
              >
                요청하신 페이지가 사라졌거나, 잘못된 경로에요. 
              </p>
            </div>
            <div>
              <div className="popup-button-wrap">
                <BaseTextButton label={'홈으로'} onClick={() => { navigate(URL_HOME)}}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BaseLayout>
  )
}