import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { BaseLayout } from 'components/layouts/BaseLayout';
import { JoinPerm } from './JoinPerm';
import { JoinIntro } from './JoinIntro';
import { JoinLogin } from './JoinLogin';
import { JoinSettingNick } from './JoinSettingNick';
import { JoinSettingRecommend } from './JoinSettingRecommend';
import { JoinSettingAnimal } from './JoinSettingAnimal';
import { JoinSettingMyArea } from './JoinSettingMyArea';
import { JoinSettingInterestArea } from './JoinSettingInterestArea';
import { JoinSettingComplete } from './JoinSettingComplete';
import { URL_HOME } from 'routes/RouteUrl';
import { AGENT } from 'constants/Mobile';

// 회원 가입 단계
const STEP = {
  PERM: 'PERM',
  INTRO: 'INTRO',
  LOGIN: 'LOGIN',
  TERM1: 'TERM1',
  NICKNAME: 'NICKNAME',
  RECOMMEND: 'RECOMMEND',
  ANIMAL: 'ANIMAL',
  MYAREA: 'MYAREA',
  INTEREST: 'INTEREST',
  COMPLETED: 'COMPLETED',
};

export const Join = ({ showAccess, common }) => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [token, setToken] = useState(null);
  const [userIdx, setUserIdx] = useState(null);
  const AGENT_TYPE = common.agentType;
  const [area, setArea] = useState('');

  // 1: 회원가입, 2: 로그인, 3: 약관동의, 4: 닉네임 설정, 5: 선호동물, 6: 내 지역 선택
  const [step, setStep] = useState(state?.step === '1' || state?.step === '3' 
                                    ? STEP.LOGIN 
                                    : (state?.step === '4' 
                                        ? STEP.NICKNAME 
                                        : (state?.step === '5' 
                                            ? STEP.ANIMAL 
                                            : (state?.step === '6' 
                                                ? STEP.MYAREA
                                                : (AGENT_TYPE !== AGENT.ANDROID_APP && AGENT_TYPE !== AGENT.IOS_APP
                                                    ? STEP.INTRO
                                                    : STEP.PERM
                                                  )
                                              )
                                          )
                                      )
                                  );

  const fnHandleArea = (area) => {
    setArea(area)
    setStep(STEP.INTEREST)
  }

  useEffect(() => {
    setToken(state?.token);
    setUserIdx(state?.idx);
  }, [state?.token, state?.idx])

  return (
    <BaseLayout header={false} footer={false}>
      <>
        {step === STEP.PERM && (<JoinPerm onMoveStep={() => setStep(STEP.INTRO)} common={common}/>)}
        {step === STEP.INTRO && (<JoinIntro onMoveStep={() => setStep(STEP.LOGIN)} common={common} />)}
        {step === STEP.LOGIN && (<JoinLogin onMoveStep={() => setStep(STEP.NICKNAME)} complete={state?.loginCmp} userIdx={userIdx} common={common} />)}
        {step === STEP.NICKNAME && (<JoinSettingNick onMoveStep={() => setStep(STEP.RECOMMEND)} userIdx={userIdx} common={common} />)}
        {step === STEP.RECOMMEND && (<JoinSettingRecommend onMoveStep={() => setStep(STEP.ANIMAL)} userIdx={userIdx} common={common} />)}
        {step === STEP.ANIMAL && (<JoinSettingAnimal onMoveStep={() => setStep(STEP.MYAREA)} userIdx={userIdx} />)}
        {step === STEP.MYAREA && (<JoinSettingMyArea onMoveStep={(area) => fnHandleArea(area)} userIdx={userIdx} showAccess={showAccess} common={common} />)}
        {step === STEP.INTEREST && (<JoinSettingInterestArea onMoveStep={() => setStep(STEP.COMPLETED)} area={area} userIdx={userIdx} />)}
        {step === STEP.COMPLETED && (<JoinSettingComplete onMoveStep={() => navigate(URL_HOME, {replace: true})} userIdx={userIdx} token={token} common={common} />)}
      </>
    </BaseLayout>
  );
};
