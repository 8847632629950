import { BaseImageButton } from 'components/elements/BaseButton';

export const FloatingButton = ({ fnClickFloatingButton }) => {

  return (
    <BaseImageButton 
      imageSrc={'/assets/images/fab-button.svg'} 
      altText={'글쓰기'}
      onClick={fnClickFloatingButton}
    />
  );
};
