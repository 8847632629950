import { useLocation, useNavigate  } from 'react-router-dom';
import { 
  ImageHomeOn,
  ImageHomeTextOn,
  ImageCommuOn,
  ImageCommuTextOn,
  ImageWalletOn,
  ImageWalletTextOn,
  ImageMoreOn,
  ImageMoreTextOn,
  ImageHomeOff,
  ImageHomeTextOff,
  ImageCommuOff,
  ImageCommuTextOff,
  ImageWalletOff,
  ImageWalletTextOff,
  ImageMoreOff,
  ImageMoreTextOff,
} from 'components/assets/menu';
import { URL_COMMUNITY, URL_HOME, URL_DIARY, URL_WALLET, URL_MORE } from 'routes/RouteUrl';
import { SpringBottomSheet } from 'components/containers';
import { HandBook, HandBookDetail } from 'pages/home';
import { useAuth } from 'hooks/AuthContext';

export function BaseFooter () {
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;

  return (
  <>
      {/* 오늘의 수첩 */}
      {pathname === URL_HOME && <SpringBottomSheet header={<HandBook />} isHeaderFix={false} content={<HandBookDetail />} position={112} />}    
      <div className={'layout-footer'}>
        <div className={'layout-footer-nav-bar'}
            onClick={() =>
              navigate(`${URL_HOME}`)
            }>
          
          {pathname === URL_HOME ? (<><ImageHomeOn /><ImageHomeTextOn /></>) : (<><ImageHomeOff /><ImageHomeTextOff /></>)}
        </div>
        <div className={'layout-footer-nav-bar'}
            onClick={() =>
              navigate(`${URL_COMMUNITY}`)
            }>
        
          {pathname === URL_COMMUNITY ? (<><ImageCommuOn /><ImageCommuTextOn /></>) : (<><ImageCommuOff /><ImageCommuTextOff /></>)}
        </div>     
        <div className={'layout-footer-nav-bar'}
            onClick={() =>
              navigate(`${URL_DIARY}`)
            }>
          {pathname === URL_DIARY || pathname === URL_WALLET ? (<><ImageWalletOn /><ImageWalletTextOn /></>) : (<><ImageWalletOff /><ImageWalletTextOff /></>)}
        </div>  
        <div className={'layout-footer-nav-bar'}
            onClick={() =>
              navigate(`${URL_MORE}`)
            }>
          {pathname === URL_MORE ? (<><ImageMoreOn /><ImageMoreTextOn /></>) : (<><ImageMoreOff /><ImageMoreTextOff /></>)}
        </div>                 
    </div>
  </>
  );
};
