import { PLEASECHECKMYAREA, SOTONGINAREA, NEEDSETAREA } from "constants";

export function LocationCheck() {
  return (
    <>
    <div className="step-4_2">
      <h2 className="title-l">{PLEASECHECKMYAREA}</h2>
      <div className="reference">
        <p className="reference-s">{SOTONGINAREA}</p>
      </div>
      <div className="map-wrap">
        <div className="location">
          <strong>{NEEDSETAREA}</strong>
        </div>
        <div className="map-box">
          <img src="/assets/images/img-3_2.png" alt="일러벡터" />
        </div>
      </div>
    </div>
  </>
  )
}