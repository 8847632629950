import client from '../axios';
import { GET_MEMBER_INTEREST_ANIMALLIST, GET_MEMBER_INTEREST_AREALIST, POST_MEMBER_BLOCK_NEIGHBORS, POST_MEMBER_INTEREST_ANIMAL, POST_MEMBER_INTEREST_AREA, POST_MEMBER_MYAREA, POST_MEMBER_NEIGHBORS, POST_MEMBER_NICKNAME, POST_MEMBER_PROFILE } from './endpoint';

export const getAnimalListApi = () => {
  return client.get(GET_MEMBER_INTEREST_ANIMALLIST);
}

export const postInterestAnimalAPI = ({data}) => {
  return client.post(POST_MEMBER_INTEREST_ANIMAL, data);
}

export const getAreaListAPI = () => {
  return client.get(GET_MEMBER_INTEREST_AREALIST);
}

export const postMyAreaAPI = ({data}) => {
  return client.post(POST_MEMBER_MYAREA, data);
}

export const postMemberNicknameAPI = ({data}) => {
  return client.post(POST_MEMBER_NICKNAME, data);
}

export const postInterestAreaAPI = ({data}) => {
  return client.post(POST_MEMBER_INTEREST_AREA, data);
}

export const postMemberProfileApi = ({ data }) => {
  return client.post(POST_MEMBER_PROFILE, data);
}

export const postMemberNeighborsAPI = ({ data }) => {
  return client.post(POST_MEMBER_NEIGHBORS, data);
}

export const postBlockNeighborsAPI = ({ data }) => {
  return client.post(POST_MEMBER_BLOCK_NEIGHBORS, data);
}