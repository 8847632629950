import { BaseInput } from "components/elements";
import {
  SELECTALL,
} from 'constants';
import { useAnimalList } from "hooks/useAnimalList";
import { useEffect, useState } from "react";


// returnMode : code - code만 리턴 / 그외 {code, name} 리턴
// showAll : true 전체선택 보여짐 / false 전체선택 영역 안보여짐
// readonly : true시 체크 불가
// defaultData : 맨 처음 체크되있는 데이터
// type : 그룹네임 없이 동물라벨만 보여짐
export const AnimalSelectBox = ({ onChoice, defaultData=undefined, returnMode="code", showAll=true, readonly=false, type=false }) => {

  const { animalList } = useAnimalList();
  const [choices, setChoices] = useState([]);
  const [allSelect, setAllSelect] = useState(false);

  const fnSelectAnimal = ({ code, name }) => {
    const codes = choices?.map(item => item.code);
    if (choices.length === 0) {
      setChoices([{ code, name }]) //초기
    } else if (codes.includes(code)) { 
      setChoices(choices.filter(item => item.code !== code)) //선택해제
    } else {
      setChoices([...choices, { code, name }])
    }
  }

  const fnSelectAll = () => {
    let allArr = [];
    if (allSelect) {
      allArr = [];
    } else {
      Object.keys(animalList).map((item) => (
        animalList[item]?.map((animal) => (
          allArr.push({ code: animal.animal_code, name: animal.animal_name })
        ))
      ))
    }
    setAllSelect(!allSelect)
    setChoices(allArr)
  }

  useEffect(() => {
    if (animalList) {
      const animalCnt = Object.values(animalList).reduce((acc, cur) => acc + cur.length, 0);
      if (choices.length === animalCnt) setAllSelect(true)
      else setAllSelect(false)
    }
    if (onChoice) {
      let returnChoices = choices;
      if (returnMode === 'code') returnChoices = choices.map(item => item.code);
      onChoice(returnChoices)
    }
  }, [choices])

  useEffect(() => {
    if (defaultData) setChoices(defaultData)
  }, [defaultData])

  return (
    <div className="checkbox-list">
      {
        showAll &&
        <div className="checkbox check-all">
          <BaseInput
            id={'checkAll'}
            name={'checkAll'}
            type={'checkbox'}
            checked={allSelect}
          />
          <label htmlFor="checkAll" onClick={fnSelectAll}>
            <span className="check-mark"></span> {SELECTALL}
          </label>
        </div>
      }
      <ul>
        {animalList && Object.keys(animalList).map((groupName) => (
          <li key={`groupName_${groupName}`}>
            {!type ? <h3>{groupName}</h3> : null}
            {
              animalList[groupName]?.map((item, idx) => (
                <div key={`animal_${item.animal_code}`} className="checkbox-button">
                  <BaseInput
                    // id={`check-use${item.animal_code}`}
                    // name={`check-use${item.animal_code}`}
                    type={'checkbox'}
                    checked={choices.map(item => item.code)?.includes(item.animal_code) ? true : false}
                  />
                  <label
                    onClick={() => {
                      if (!readonly) fnSelectAnimal({ code: item.animal_code, name: item.animal_name })
                    }}
                    //htmlFor={`check-use${item.animal_code}`}
                  >
                    {item.animal_name}
                  </label>
                </div>
              ))
            }
          </li>
        ))}
      </ul>
    </div>
  );
};