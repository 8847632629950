import { IconLevel } from "components/assets/icon";
import { BaseIconButton } from "components/elements/BaseButton";
import { NEIGHBORS, REPLY, BOARD, UNBLOCK_NEIGHBORS } from "constants";
import { useNavigate } from "react-router-dom";
import { URL_PROFILE } from "routes/RouteUrl";
import { ProfileImage } from "./ProfileImage";

// onClickUnblock 추가 - 차단 해제 클릭 시
// isBlocked 추가 - 차단 이웃인 경우 [차단해제] 아닌 경우 [관심이웃]
export function ProfileContainer({item, onSaveTab, onClickUnblock=undefined, isBlocked=false}) {
  const navigate = useNavigate();
  
  // console.log(item)
  return (
    <>
      <div className="profile-box">
        <ProfileImage 
          size={'56'}
          imageSrc={item?.profile_img_path}
        />
        <div className="profile-info">
          <div className="profile-info-item">
            <div className="info">
              <span className="nickname">{item?.user_nickname}</span>
              <span className="level">{item?.user_level_name}</span>
              <IconLevel level={item?.user_level} altText={item?.user_level_name} />
            </div>
            <div className="util">
              <div className="util-item">
                <p>{BOARD}</p>
                <p>{item?.write_cnt}</p>
              </div>
              <div className="util-item">
                <p>{REPLY}</p>
                <p>{item?.comment_cnt}</p>
              </div>
              <div className="util-item">
                <p>{NEIGHBORS}</p>
                <p>{item?.neighbors_cnt}</p>
              </div>
            </div>
          </div>
          <div className="profile-info-item">
            { !isBlocked && 
              <BaseIconButton className="round-g"
                onClick={() => {
                  navigate(URL_PROFILE.replace(':id', item?.idx), { state: { info: item } });
                  onSaveTab();
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M8.56131 3.16207L7.99969 3.72297L7.43879 3.16207C6.66431 2.3876 5.64894 2 4.63356 2C3.61819 2 2.60281 2.3876 1.82834 3.16207C0.279388 4.71102 0.279388 7.22285 1.82834 8.77252L2.38996 9.33342L7.99969 14.9439L13.6101 9.33342L14.171 8.77252C15.7207 7.22285 15.7207 4.71102 14.171 3.16207C13.3966 2.3876 12.3812 2 11.3665 2C10.3512 2 9.33578 2.3876 8.56131 3.16207Z"
                    fill="#40DF9F"
                  />
                </svg>
                <span>{NEIGHBORS}</span>
              </BaseIconButton>
            }
            {
              isBlocked &&
              <BaseIconButton className="round-r"
                onClick={() => {
                  if (onClickUnblock) onClickUnblock({ blockUserIdx: item?.idx });
                  onSaveTab();
                }}
              >
                <span>{UNBLOCK_NEIGHBORS}</span>
              </BaseIconButton>
            }
          </div>
        </div>
      </div>
    </>
  );
}