import { AGENT } from "constants/Mobile";

// 안드로이드인지 애플인지 확인
export const isMobile = {
  Android: function () {
    return navigator.userAgent.match(/Android/i) == null ? false : true;
  },
  iOS: function () { // 모바일웹
    return (navigator.userAgent.match(/iPhone/i) == null  && navigator.userAgent.match(/pethome.ios/i) == null) ? false : true;
  },
  iOSApp: function () { // 앱 (사용안함)
    return navigator.userAgent === 'pethome.ios'
  }
};

export const getAgentType = () => {
  if (isMobile.Android()) {
    if (window.pethome) return AGENT.ANDROID_APP
    else return AGENT.ANDROID_WEB
  } else if (isMobile.iOS()) {
    if (window.webkit) return AGENT.IOS_APP
    else return AGENT.IOS_WEB
  } else {
    return AGENT.PC
  }
}

// 앱에 데이터 세팅 (안드로이드 전용)
function set({ key, data }) {
  if(isMobile.Android()) {
    //alert(JSON.stringify(window, getCircularReplacer()))
    window.pethome.setdata(JSON.stringify({
      item_name: key,
      item_value: data
    }));
  } else if (isMobile.iOS()) {
    window.webkit.messageHandlers.setdata.postMessage(JSON.stringify({
      item_name: key,
      item_value: data
    }));
    // window.webkit.messageHandlers.setdata.postMessage({
    //   item_name: key,
    //   item_value: data
    // });
  } else {
    window.sessionStorage.setItem(key, data);
  }
}

// 앱에서 데이터 가져옴 (애플 전용)
function getIOS(key, callback) {
  var jsonData = JSON.stringify({ item_name: key });
  window.webkit.messageHandlers.getdata.postMessage(jsonData);
  
  // 콜백 함수를 통해 데이터 리턴
  window.handleGetDataResponse = function(data) {
    callback(data);
  };
}

// 앱에서 데이터 가져옴 (안드로이드 전용)
function get(key){
  if(isMobile.Android()) {
    return window.pethome.getdata(JSON.stringify({
      item_name: key
    }));
  } else if(isMobile.iOS()) {
  } else {
    return window.sessionStorage.getItem(key);
  }
}

// 앱 권한 확인 (안드로이드 전용)
function permission({ type }) {
  if(isMobile.Android()) {
    const permissionYN = get(type);
    // alert(permissionYN)
    if (permissionYN && permissionYN === '1') {
      return true
    } else {
      return false
    }
  } 
}
// 앱 권한 확인 (애플 전용)
function permissionIOS ({ type }) {
  if(isMobile.iOS()) {
    return new Promise((resolve, reject) => {
      getIOS(type+"", (data) => { // 애플은 타입을 스트링으로 보내야함!
        const permissionYN = data 
        if (permissionYN && permissionYN === '1') {
          resolve(true)
        } else {
          resolve(false)
        }
      })
    });
  } 
}


// 앱 권한 요청
function requestPermission({ type }) {
  if(isMobile.Android()) {
    window.pethome.requestPermission(type)
    return true
  } else if (isMobile.iOS()) {  
    // alert('권한요청시작'+type)
    const permissionData = {
      permissionType: type, // Camera Permission
      actionType: 1,
      userIdx: 131,
      contentIdx: 0
    }
    window.webkit.messageHandlers.requestPermission.postMessage(permissionData);
  } else {
  }
}

// 카메라 호출
function camera({ userIdx, actionType, contentIdx }) {
  if(isMobile.Android()) {
    // alert('카메라 호출 userIdx: '+userIdx + ' actionType:' + actionType + ' contentIdx: '+ contentIdx)
    window.pethome.startCamera(Number(userIdx), actionType, Number(contentIdx));
  } else if (isMobile.iOS()) {
    const data = {
      actionType,
      userIdx: Number(userIdx),
      contentIdx: Number(contentIdx)
    }
    window.webkit.messageHandlers.startCamera.postMessage(data);
  }
}

// 앨범 호출
function albumn({ userIdx, actionType, contentIdx, max }) {
  if(isMobile.Android()) {
    // alert('앨범 호출 userIdx: '+userIdx + ' actionType:' + actionType + ' contentIdx: '+ contentIdx)
    window.pethome.startGallery(Number(userIdx), actionType, Number(contentIdx), Number(max));
  } else if (isMobile.iOS()) {
    const data = {
      actionType,
      userIdx: Number(userIdx),
      contentIdx: Number(contentIdx),
      max: Number(max)
    }
    window.webkit.messageHandlers.startGallery.postMessage(data);
  }
}

// 연락처 열기 후 메시지 전송
function contact({ item_nickname, item_code }) {
  const item_message = "모든 반려동물이 집, 어서오세요. 컴펫홈!\n\n"+
  `[${item_nickname}]님께서 컴펫홈에 초대하고 있어요.\n`+
  "지금 바로 초대받고 동물 가족을 함께 돌봐요.\n\n"+
  `아래 링크로 앱을 다운로드 받기만 하면 컴펫홈의 모든 혜택을 누릴 수 있어요!\n\n`+
  "● 컴펫홈 시작하기 ●\n"+
  (isMobile?.Android() ? `https://play.google.com/store/apps/details?id=com.mcb.compethome?referrer=invite=${item_code}\n\n` 
                      : `https://play.google.com/store/apps/details?id=com.mcb.compethome?referrer=invite=${item_code}\n\n`) +
  "혹시 이미 앱을 다운 받으셨나요?\n"+
  "수첩 설정 > 수첩 공유받기에서 해당 코드를 입력해주세요.\n\n"+
  "● 공유코드 ●\n"+
  `${item_code}`;
  
  if(isMobile.Android()) {
    window.pethome.openContact(JSON.stringify({
      // item_nickname: item_nickname,
      // item_code: item_code,
      item_message: item_message
    }));
  } else if (isMobile.iOS()) {
    window.webkit.messageHandlers.openContact.postMessage(JSON.stringify({
      // item_nickname: item_nickname,
      // item_code: item_code,
      item_message: item_message
    }));
  } else {
    
  }
}

// 공유하기
function share({ item_title, item_content, item_url }) {
  if(isMobile.Android()) {
    window.pethome.openShare(JSON.stringify({
      item_title: item_title,
      item_content: item_content,
      item_url: item_url,
    }));
  } else if (isMobile.iOS()) {
    window.webkit.messageHandlers.openShare.postMessage(JSON.stringify({
      item_title: item_title, 
      item_content: item_content, 
      item_url: item_url
    }));
  }
}

// 구독결제하기
function purchase({ index }) {
  // alert('첫결제:index:'+index)
  if(isMobile.Android()) {
    window.pethome.startPurchase(index);
  } else if (isMobile.iOS()) {
    window.webkit.messageHandlers.startPurchase.postMessage({
      index: index
    });
  }
}

// 구독변경하기
function purchaseChange({ index, token }) {
  // alert('변경결제:index:'+index+'token:'+token)
  if(isMobile.Android()) {
    window.pethome.startPurchaseChange(index, token);
  } else if (isMobile.iOS()) { // iOS는 변경 불가
  }
}

// 구독취소하기
function cancelSubscription() {
  // alert('구독취소화면호출')
  if(isMobile.Android()) {
    window.pethome.cancelSubscription();
  } else if (isMobile.iOS()) { 
    window.webkit.messageHandlers.cancelSubscription.postMessage('');
  }
}

// 앱리뷰하기
function review() {
  if(isMobile.Android()) {
    window.pethome.openReview();
  } else if (isMobile.iOS()) {
    window.webkit.messageHandlers.openReview.postMessage('');
  } 
}

// 브라우저열기
function openBrowser({ link }) {
  if(isMobile.Android()) {
    window.pethome.openbrowser(link);
  } else if (isMobile.iOS()) {
    window.webkit.messageHandlers.openbrowser.postMessage(link);
  } 
}

// 피씨용 위치 호출
export const getLocation = (callback, errorCallback) => {
  //console.log('[getLocation] call getLocation')
  navigator.geolocation.getCurrentPosition(async (position) => {
    // console.log('[getLocation] 위치:'+position.coords.latitude+":::"+position.coords.longitude)
    if (callback) callback(position)
  },
  (err) => {
    console.error('[getLocation] getLocation 에러 발생')
    if (errorCallback) errorCallback(err);
  })
}



export const $set = ({ key, data }) => {
  set({key, data});
}
export const $get = (key) => {
  return get(key);
}
export const $getIOS = (key, callback) => {
  getIOS(key, callback);
}
export const $permission = ({ type }) => {
  return permission({ type });
}
export const $permissionIOS = ({ type }) => {
  return new Promise((resolve, reject) => {
    try {
      permissionIOS({ type })
      .then((res) => {
          resolve(res)
      })
    } catch (err) {
      reject(err)
    }
  })
}
export const $requestPermission = ({ type }) => {
  return requestPermission({ type });
}
export const $camera = ({ userIdx, actionType, contentIdx }) => {
  camera({ userIdx, actionType, contentIdx });
}
export const $albumn = ({ userIdx, actionType, contentIdx, max }) => {
  albumn({ userIdx, actionType, contentIdx, max });
}
export const $contact = ({ item_nickname, item_code }) => {
  contact({ item_nickname, item_code });
}
export const $share = ({ item_title, item_content, item_url }) => {
  share({ item_title, item_content, item_url });
}
export const $purchase = ({ index }) => {
  purchase({ index })
}
export const $purchaseChange = ({ index, token }) => {
  purchaseChange({ index, token })
}
export const $cancelSubscription = () => {
  cancelSubscription()
}
export const $openBrowser = ({ link }) => {
  openBrowser({ link })
}
export const $review = () => {
  review()
}