import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';
import { fonts } from './fonts';

const GlobalStyles = createGlobalStyle`
  ${fonts}
  
`;
// const GlobalStyles = createGlobalStyle`
//   ${reset};
//   a {
//     text-decoration:none;
//     color:inherit;
//   }
//   ${fonts}
//   * {
//     box-sizing: border-box;
//   }
//   html {
//     font-size: 62.5%;
//   }
//   html, body, div, span, h1, h2, h3, h4, h5, h6, p, 
//   a, dl, dt, dd, ol, ul, li, form, label, table {
//     margin: 0;
//     padding: 0;
//     border: 0;
//     /* font-size: 1rem; */
//     /* vertical-align: baseline; */
//     /* background-color: #ffffff; */
//     /* background-color: rgba(0, 0, 0, 0); */
//   }
//   body {
//     line-height: 1.25;
//     -webkit-text-size-adjust:none /* 뷰표트 변환시 폰트크기 자동확대 방지 */
//     font-family: 'Noto Sans KR';
//     font-size: 1.4rem;
//     /* background-color: rgba(0, 0, 0, 0); */
//   }
//   ol, ul {
//     /* list-style: none; */
//   }  
// `;

export default GlobalStyles;
