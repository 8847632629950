import { useEffect } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import useBottomSheet from "./useBottomSheet";
import BottomSheetHeader from "./BottomSheetHeader";
/**
 * ref) Wayne Kim
 * https://blog.mathpresso.com/bottom-sheet-for-web-55ed6cc78c00
 */

export const MIN_Y = 60; // BottomSheet 최대 Y
export const MAX_Y = window.innerHeight - 0; // BottomSheet 최소 Y
export const BOTTOM_SHEET_HEIGHT = window.innerHeight - MIN_Y; // BottomSheet height

const BottomSheetWrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;

  position: fixed;
  z-index: 1;
  top: calc(100% - 8.9rem); /* BottomSheet 위치 */
  left: 0;
  right: 0;

  height: ${BOTTOM_SHEET_HEIGHT}px;
  width: 100%;
  max-width:720px;
  margin: auto;
  // border: 1px solid #bdc3c7;
  border-radius: 24px 24px 0px 0px;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.35);
  background: #fff;
`;

const ContentWrapper = styled.div`
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  /* background: red; */
`;

export function HandBookBottomSheet({ title, children, setMaxY}) {
  const { sheet, content } = useBottomSheet();
  // 모바일에서 스크롤이 안되는 문제로 높이 설정을 하기위해 추가함
  
  useEffect(()=>{
    setMaxY(MAX_Y);
    
  },[])
  return (
    <BottomSheetWrapper
      ref={sheet}
      initial="initial"
      animate="target"
      transition={{
        ease: "easeInOut",
        duration: 0.7
      }} /** animation speed */
    >
      <>
      {title}
      {/* <div className="handbook">
        <div className="hand-off">
          <div className="handbook-wrap">
            <div className="title">
                <strong>오늘의 수첩</strong>
                <button type="button"><img src="/assets/images/arrow-top.png" alt="열기" /></button>
            </div>
          </div>
        </div>
      </div> */}
      </>
      <ContentWrapper ref={content}>
        {children}
      </ContentWrapper>
    </BottomSheetWrapper>
  );
}