import { css } from 'styled-components';

import NotoSansThin from '../assets/fonts/NotoSans-Thin.ttf';//100
import NotoSansExtraLight from '../assets/fonts/NotoSans-ExtraLight.ttf';//200
import NotoSansLight from '../assets/fonts/NotoSans-Light.ttf';//300
import NotoSansRegular from '../assets/fonts/NotoSans-Regular.ttf';//400
import NotoSansMedium from '../assets/fonts/NotoSans-Medium.ttf';//500
import NotoSansSemiBold from '../assets/fonts/NotoSans-SemiBold.ttf';//600
import NotoSansBold from '../assets/fonts/NotoSans-Bold.ttf';//700
import NotoSansExtraBold from '../assets/fonts/NotoSans-ExtraBold.ttf';//800
import NotoSansBlack from '../assets/fonts/NotoSans-Black.ttf';//900

import GmarketSansLight from '../assets/fonts/GmarketSansTTFLight.ttf';
import GmarketSansMedium from '../assets/fonts/GmarketSansTTFMedium.ttf';
import GmarketSansBold from '../assets/fonts/GmarketSansTTFBold.ttf';
import Jua from '../assets/fonts/Jua-Regular.ttf';

// import NotoSansRegular from '../assets/fonts/NotoSansKR-Regular.ttf';
// import NotoSansMedium from '../assets/fonts/NotoSansKR-Medium.ttf';
// import NotoSansBold from '../assets/fonts/NotoSansKR-Bold.ttf';

export const fonts = css`
  @font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url(${NotoSansThin}) format('truetype');
  }
  @font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url(${NotoSansExtraLight}) format('truetype');
  }
  @font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(${NotoSansLight}) format('truetype');
  }
  @font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(${NotoSansRegular}) format('truetype');
  }
  @font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(${NotoSansMedium}) format('truetype');
  }
  @font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(${NotoSansSemiBold}) format('truetype');
  }
  @font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(${NotoSansBold}) format('truetype');
  }
  @font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url(${NotoSansExtraBold}) format('truetype');
  }
  @font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url(${NotoSansBlack}) format('truetype');
  }


  @font-face {
    font-family: 'Jua';
    font-style: normal;
    font-weight: 400;
    src: url(${Jua}) format('truetype');
  }

  @font-face {
    font-family: 'Gmarket Sans';
    font-style: normal;
    font-weight: 300;
    src: url(${GmarketSansLight}) format('truetype');
  }
  @font-face {
    font-family: 'Gmarket Sans';
    font-style: normal;
    font-weight: 500;
    src: url(${GmarketSansMedium}) format('truetype');
  }
  @font-face {
    font-family: 'Gmarket Sans';
    font-style: normal;
    font-weight: 700;
    src: url(${GmarketSansBold}) format('truetype');
  }

  // @font-face {
  //   font-family: 'Noto Sans KR';
  //   font-style: normal;
  //   font-weight: normal;
  //   font-display: swap;
  //   src: url(${NotoSansRegular}) format('truetype');
  // }

  // @font-face {
  //   font-family: 'Noto Sans KR';
  //   font-style: normal;
  //   font-weight: 500;
  //   font-display: swap;
  //   src: url(${NotoSansMedium}) format('truetype');
  // }

  // @font-face {
  //   font-family: 'Noto Sans KR';
  //   font-style: normal;
  //   font-weight: 700;
  //   font-display: swap;
  //   src: url(${NotoSansBold}) format('truetype');
  // }



`;
