import { combineReducers } from 'redux';

import AuthReducer from 'store/auth-reducer';
import PushReducer from 'store/push-reducer';
import CommonReducer from 'store/common-reducer';

const rootReducers = combineReducers({
  push: PushReducer,
  auth: AuthReducer,
  common: CommonReducer,
});

export default rootReducers;
