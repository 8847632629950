import { useDiaryWalllet } from "hooks/useDiaryWallet"
import { formatDate } from "utils/utils"

/*
오늘의 수첩 - 다이어리
검색 (메인&상세) - 다이어리
*/
export const TodayDiaryListItem = ({ dataList, divClassName='', onClick }) => {

  const { WriteUpdateCnt } = useDiaryWalllet();

  const fnHandleClick = (date) => {
    if (onClick) onClick(date)
  }
  return (
    dataList.map((item, idx) => 
      <div key={`search_diary_${idx}`} className={divClassName} onClick={() => fnHandleClick(item.diary_date)}>
          { item.file_path && <img className="log-img" src={item.file_path} alt=""/> }
          <div className="log-text-box">
              <h3 className="title-xs">{item.title}</h3>
              <p className="text">{item.content}</p>
          </div>
          <p className="info">
              <span className="time">{ formatDate(item.update_date !== null ?  item.update_date : item.create_date, 'HHMM', ':') }</span>
              <span className="nick-name">{item.user_nickname} <WriteUpdateCnt data={item.diaryHistoryList}/></span>
          </p>
      </div>
    )
  )
}