import { Loading } from "components/containers";
import { ACCESS_TYPE, AGENT } from "constants/Mobile";
import { useAccessPermission } from "hooks/useAccessPermission";
import { useEffect, useState } from "react";
import { $get, $getIOS, $set } from "utils/mobile";

export const JoinPerm = ({ common, onMoveStep }) => {
  const AGENT_TYPE = common.agentType;
  const { requestAccess } = useAccessPermission();
  const [isShow, setShow] = useState({ 
    loading: false,
  });

  // 로딩 토글
  const fnToggleLoading = (value) => {
    setShow({ ...isShow, loading: value })
  }

  const fnCheckAccess = () => {
    fnToggleLoading(true)
    if (AGENT_TYPE === AGENT.IOS_APP || AGENT_TYPE === AGENT.ANDROID_APP) {
      $set({key: 'visited', data: 'true'});
    } 
    setTimeout(() => {
      fnToggleLoading(false)
      onMoveStep();
    }, 500);
  }

  useEffect(() => {
    if (AGENT_TYPE === AGENT.ANDROID_APP) { // 안드로이드
      requestAccess({ type: ACCESS_TYPE.NOTIFICATION })
      const visited = $get('visited');
      if (visited) onMoveStep();
    }
    if (AGENT_TYPE === AGENT.IOS_APP) {
      $getIOS('visited', (data) => { 
        if (data) onMoveStep()
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div className="wrap wrap-join intro-access">
        <div className="member">
          <div className="main">
            <h1 className="title-xl">
              앱 사용을 위해
              <br /> 접근 권한을 허용해 주세요
            </h1>
            <ul>
              <li>
                <img src="/assets/images/icon-0.svg" alt="" />
                <div>
                  <strong>기기 및 앱 기록</strong>
                  <p>서비스 개선 및 오류 확인</p>
                </div>
              </li>
              <li>
                <img src="/assets/images/icon-1.svg" alt="" />
                <div>
                  <strong>저장공간</strong>
                  <p>가계부/메모 작성 시 데이터 저장</p>
                </div>
              </li>
              <li>
                <img src="/assets/images/icon-35.svg" alt="" />
                <div>
                  <strong>
                    알림 <span className="point-g">(선택)</span>
                  </strong>
                  <p>푸시알림 등록 및 수신</p>
                </div>
              </li>
              <li>
                <img src="/assets/images/icon-2.svg" alt="" />
                <div>
                  <strong>
                    위치 <span className="point-g">(선택)</span>
                  </strong>
                  <p>게시글 등록 시 위치 등록</p>
                </div>
              </li>
              <li>
                <img src="/assets/images/icon-3.svg" alt="" />
                <div>
                  <strong>
                    사진 / 카메라 <span className="point-g">(선택)</span>
                  </strong>
                  <p>게시글 작성 시 사진/동영상 촬영</p>
                </div>
              </li>
            </ul>
            <div className="reference">
              <p className="reference-xs">
                선택 권한의 경우 허용하지 않아도 서비스를 사용할 수 있으나 일부
                서비스 이용이 제한될 수 있습니다.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="button-wrap">
        {/* <!-- button을 a요소로 변경 가능 --> */}
        <button className="active" type="button" onClick={fnCheckAccess}>
          확인
        </button>
      </div>
      {/* 로딩바 */}
      <Loading loading={isShow.loading}/>
    </>
  );
};
