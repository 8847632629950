import { useState } from 'react';
import { AreaPickerModal, Loading } from 'components/containers';
import { BaseButton, BaseImageButton } from 'components/elements';
import { BaseLayout, BasePageHeader } from 'components/layouts';
import { JOIN } from 'constants';
import {
  PLEASESELECT,
  INTERESTAREA,
  SELECT,
  OTHERAREACANSEE,
  MYAREA,
  OTHERAREA,
  LIMITTWO,
  NEXT,
  SELECTMYAREA,
} from 'constants';
import { postInterestAreaAPI } from 'api/member';
import { useErrorHandler } from 'hooks/useErrorHandler';

export const JoinSettingInterestArea = ({
  onMoveStep,
  area,
  userIdx,
}) => {
  const [selectedArea, setSelectedArea] = useState({
    sido: null,
    gugun: null,
    code: null,
    idx: null,
  });
  const [openModal, setOpenModal] = useState({
    openAreaPickerModal: false,
  });
  const [firstArea, setFirstArea] = useState({
    sido: null,
    gugun: null,
    code: null,
  });
  const [secondArea, setSecondArea] = useState({
    sido: null,
    gugun: null,
    code: null,
  });
  const [isShow, setShow] = useState({ 
    loading: false,
  });
  const [errorData, setErrorData] = useState(null);
  const { ErrorModal } = useErrorHandler({ errorData });


  // 로딩 토글
  const fnToggleLoading = (value) => {
		setShow({ ...isShow, loading: value })
	} 

  const fnSelectAreaOk = (area) => {
    if (selectedArea?.idx === 1) {
      setFirstArea({ sido: area.sido, gugun: area.gugun, code: area.code });
    } else if (selectedArea?.idx === 2) {
      setSecondArea({ sido: area.sido, gugun: area.gugun, code: area.code });
    }
    setOpenModal({ ...openModal, openAreaPickerModal: false });
    //setSelectedArea({sido: null, gugun: null, code: null, idx: null});
  };

  const fnOpenAreaPickerModal = (idx) => {
    setOpenModal({ ...openModal, openAreaPickerModal: true });
    const initData = idx === 1 ? firstArea : secondArea; // 재선택시 지역선택 모달 주소 기본값
    setSelectedArea({ ...initData, idx: idx });
  };

  const fnCloseAreaPickerModal = () => {
    setOpenModal({ ...openModal, openAreaPickerModal: false });
  };

  const fnCheckInterestArea = () => {
    let codeArr = [];
    if (firstArea.code) codeArr.push(firstArea.code);
    if (secondArea.code) codeArr.push(secondArea.code);
    codeArr.length > 0 ? fnPostInterestArea(codeArr) : onMoveStep();
  };

  const fnPostInterestArea = async (codeArr) => {
    const data = {
      user_idx: userIdx,
      area_code: codeArr,
    };
    // console.log(data);
    fnToggleLoading(true);
    await postInterestAreaAPI({ data })
      .then((res) => {
        // console.log(res);
        onMoveStep();
      })
      .catch(err => {
        console.error(err);
        setErrorData(err);
      })
      .finally(() => {
        fnToggleLoading(false);
      })
  };

  return (
    <>
    <BaseLayout header={false} footer={false}>
      <BasePageHeader 
        title={JOIN}
        pageType={'join'}
      />
      <div className="wrap wrap-join">
        <div className="member">
          <div className="join">
            <div className="step-5">
              <h2 className="title-l">
                {INTERESTAREA}<br />
                {PLEASESELECT} <span className="point-g">({SELECT})</span>
              </h2>
              <div className="reference">
                <p className="reference-s">{OTHERAREACANSEE}</p>
              </div>
              <div className="content">
                <div className="map-wrap">
                  <div className="bookmark-box">
                    <div className="bookmark">
                      <h3>{MYAREA}</h3>
                      <div className="location">
                        <strong>{area.area}</strong>
                      </div>
                    </div>
                    <div className="bookmark">
                      <h3>
                        {OTHERAREA} <span className="reference-s">({LIMITTWO})</span>
                      </h3>
                      {!firstArea.sido && !firstArea.gugun ? (
                        <div className="bookmark-add">
                          <BaseImageButton
                            imageSrc={'/assets/images/ico_plus_grayT500.svg'}
                            altText={SELECTMYAREA}
                            onClick={() => fnOpenAreaPickerModal(1)}
                          ></BaseImageButton>
                        </div>
                      ) : (
                        <div
                          className="location"
                          onClick={() => fnOpenAreaPickerModal(1)}
                        >
                          <strong>
                            {firstArea.sido} {firstArea.gugun}
                          </strong>
                        </div>
                      )}
                      {!secondArea.sido && !secondArea.gugun ? (
                        <div className="bookmark-add">
                          <BaseImageButton
                            imageSrc={'/assets/images/ico_plus_grayT500.svg'}
                            altText={'지역 선택'}
                            onClick={() => fnOpenAreaPickerModal(2)}
                          ></BaseImageButton>
                        </div>
                      ) : (
                        <div
                          className="location"
                          onClick={() => fnOpenAreaPickerModal(2)}
                        >
                          <strong>
                            {secondArea.sido} {secondArea.gugun}
                          </strong>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="button-wrap">
          <BaseButton label={NEXT} onClick={fnCheckInterestArea} />
        </div>
      </div>

      {/* 에러 */}
      {ErrorModal}
      </BaseLayout>

      {/* 로딩바 */}
      <Loading loading={isShow?.loading}/>
      
      {/* 모달 영역 */}
      <AreaPickerModal
        show={openModal.openAreaPickerModal}
        initArea={selectedArea}
        onOk={fnSelectAreaOk}
        onClose={fnCloseAreaPickerModal}
      />
    </>
  );
};
