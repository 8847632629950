import { useEffect, useState } from "react";
import moment from "moment";
import { DAYS_LABEL } from "constants/Label";
import { getHoiliday } from "data/holiday";

/**
 * 요일
 * @returns 
 */
function DayCell({ value }) {
  return (
    <div className="cmp-simple-calendar-day-wrap">
      <div className="cmp-simple-calendar-day-label">{value}</div>
    </div>
  );
}
/**
 * 일
 * @returns 
 */
function DateCell({ today, selectedDay, date, holidays, value, onChange }) {
  const thisMonth = selectedDay ? selectedDay.slice(0, 6) : today.slice(0, 6);
  const month = date.slice(0, 6);
  const day = Number(date.slice(-2));

  // console.log(`thisMonth: ${thisMonth} month: ${month}`);

  let dateStyle = {};

  if (thisMonth !== month) {
    dateStyle = { ...dateStyle, color: 'var(--color-grayb-300)' };
  }

  if (date === today) {
    dateStyle = { ...dateStyle, color: 'var(--color-today-date)' , backgroundColor: 'var(--bgcolor-today-date)' };
  }
  if (date === selectedDay) {
    dateStyle = { ...dateStyle, color: '#ffffff', fontWeight: 'bold', backgroundColor: 'var(--color-main-500)' };
  }
  // 공휴일 표시
  if (holidays?.includes(date)) {
    if (thisMonth !== month) {
      dateStyle = { ...dateStyle, color: 'var(--color-holiday-not-thismonth)'};
    } else {
      dateStyle = { ...dateStyle, color: 'var(--color-holiday)'};
    }
  }

  return (
    <div className='cmp-diary-calendar-date-wrap' onClick={() => onChange(date)}>
      <div className='cmp-diary-calendar-date-label' style={{ ...dateStyle }}>{day}
      
      </div>
      <div className='cmp-diary-calendar-money-label'>{value}</div>
    </div>
  );
}

export function DiaryCalendar({ defaultDate, values, onChange }) {
  const [today] = useState(moment().format("YYYYMMDD"));
  //const [selectedDay, setSelectedDay] = useState(defaultDate);
  const [dates, setDates] = useState([]);
  const [holidays, setHolidays] = useState([]);

  const getDates = () => {
    // 현재 달 첫째 날
    const firstDate = moment(defaultDate).clone().startOf("month").day(0); // 0 (요일 - 일요일 : 0, 토요일 : 6)

    // 현재 달 마지막 날
    // const lastDate = moment(today).clone().endOf("month").day(6);

    const dateStack = [];

    let curDate = firstDate;
    // 6 week
    for (let i = 0; i < 42; i++) {
      dateStack.push(curDate.format("YYYYMMDD"));
      curDate = curDate.clone().add(1, "days");
    }

    // console.log(`firstDate : ${firstDate}`);
    // console.log(`lastDate : ${lastDate}`);
    console.log(`dateStack : `, dateStack);

    setDates(dateStack);
    setHolidays(getHoiliday(dateStack));
  };

  const onChangeDate = (value) => {
    //setSelectedDay(value);
    if (onChange) onChange(value);
  };
  useEffect(() => {
    getDates();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultDate]);

  return (
    <>
      <div className="cmp-simple-calendar-week pd-20">
        {DAYS_LABEL?.map((value) => {
          return <DayCell key={value} value={value} />;
        })}
      </div>
      <div className="cmp-simple-calendar-week pd-20">
        {dates?.map((date) => {
          // 해당 날짜 데이터가 있는 지 조회
          const value = values?.filter(value => value.date === date)?.[0]?.value;

          return (
            <DateCell
              key={date}
              today={today}
              selectedDay={defaultDate}
              date={date}
              holidays={holidays}
              value={value}
              onChange={onChangeDate}
            />
          );
        })}
      </div>
    </>
  );
}
