import { getAnimalListApi } from "api/member";
import { useEffect, useState } from "react";

export const useAnimalList = () => {
  const [animalList, setAnimalList] = useState(null);

  useEffect(() => {
    const fnGetAnimalList  =  () => {
      getAnimalListApi()
        .then(res => {
          const { data_list } = res.data;
          const animalList = data_list?.reduce((acc, current) => {
            const key = current.animal_group_name;
            
            if (!acc[key]) {
              acc[key] = [];
            }
        
            acc[key].push(current);
            return acc;
          }, {});
          // console.log(animalList)
          setAnimalList(animalList);
          
        })
        .catch(err => {
          console.error(err)
        })
    }
    fnGetAnimalList();
  }, [])

  return { animalList }
}