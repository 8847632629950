//import { Popup } from 'antd-mobile';
import { IconCphLogo } from 'components/assets/icon';
import { BottomPopUp, ProfileImage } from 'components/containers';
import { BaseImageButton, BaseButton } from "components/elements";
import { MYPETINFO, CHECK, CLOSE, ALL, PETAGE } from 'constants';
import { useEffect, useState } from 'react';

export const MyPetModal = ({ show, dataList, defaultData=undefined, onClose, onOk, onClick }) => {

  const [choice, setChoice] = useState({ idx: defaultData } || 0);

  const fnClickItem = (idx) => {
    // case - 한개만 선택 가능 
    setChoice(idx);

    if (onClick) onClick(idx);
  }

  const fnHandleClose = () => {
    if (onClose) onClose();
  }

  // 24.08.01 mcb 요청에 의한 모달내 확인버튼 일괄 삭제
  // const fnHandleOk = () => {
  //   if (onOk) onOk(choice);
  // }

  const fnHandleMaskClick = () => {
    if (onClose) onClose();
  }

  useEffect(() => {
    // 그냥 닫은 경우 원래데이터 원복을 위함
    setChoice({ idx: defaultData })
  }, [show])

  const ListItem = ({ item, className, onClick }) => {
    return (
      <li className={`animal-li ${choice.idx === item.idx ? 'checked': ''}`} onClick={() => onClick({ idx: item.idx, name: item.pet_name, img: item.img_url })}>
        <div className="profile-horizental profile-img-40">
            <ProfileImage size={40} imageSrc={item.img_url}/>
            <div className="profile-content">
              <p className="text-bold">{item.pet_name}</p>
              <div className="text flex-align">
                  <div className="label-l">{item.animal_name}</div>
                  <span className="animal-info">
                    <span>{item.sex_name}</span> <span>{`${item.age}`}</span> <span>{item.weight}kg</span>
                  </span>
              </div>
            </div>
        </div>
      </li>
    )
  }

  return (
    // <Popup
    //   visible={show}
    //   mask={mask}
    //   position={position}
    //   bodyStyle={{
    //     ...bodyStyle,
    //     minHeight: '27rem',
    //   }}
    // >
    <BottomPopUp
      show={show}
      hasBottom={false}
      onMaskClick={fnHandleMaskClick}
    >
      <div className="popup-bottom">
        <div className="popup-wrap my-pet-modal">
          <div className="popup-head popup-close">
              <strong>{MYPETINFO}</strong>
              <BaseImageButton imageSrc={"/assets/images/icon-close.svg"} altText={CLOSE} onClick={fnHandleClose}/>
          </div>
          <div className="popup-content">
          {/* account-book-write-family */}
            <ul className="animal-list">
              <li className={choice.idx === -1 ? 'checked': ''} onClick={() => onOk({ idx: -1, name: ALL})}>
                <div className="all-choice">
                  <IconCphLogo altText={ALL}/>
                  <span className="text-bold">{ALL}</span>
                </div>
              </li>
              {
                dataList?.map((item, idx) => 
                  <ListItem item={item} onClick={({ idx, name, img }) => onOk({ idx, name, img })} key={`pet-list-idx-${idx}`}/>
                )
              }
            </ul>
          </div>
          {/* 24.08.01 mcb 요청에 의한 모달내 확인버튼 일괄 삭제 */}
          {/* <div className="popup-button-wrap">
            <BaseButton label={CHECK} onClick={fnHandleOk}/>
          </div> */}
        </div>
      </div>
    </BottomPopUp>
    // </Popup>
  )
}