import { Image, Swiper } from "antd-mobile";
import { AGENT } from "constants/Mobile";
import { useNavigate } from "react-router-dom";
import { $openBrowser } from "utils/mobile";

export function BannerContainer({ common, dataList }) {
  const navigate = useNavigate();

  const fnMoveToLink = (url) => {
    //const link = 'http://' + url;
    const curLoc = window.location.href;

    if (common.agentType === AGENT.ANDROID_APP || common.agentType === AGENT.IOS_APP) {
      if (url.includes(curLoc)) {
        const path = `/${url.split(curLoc)[1]}`
        navigate(path)
      } else {
        $openBrowser({link : url});
      }
    } else {
      window.open(url);
    }
    
  }

  const items = dataList.map((item, key) => (
    <Swiper.Item key={`item_${key}`}>
      <div onClick={() => fnMoveToLink(item?.img_url)}>
        <Image src={item?.img_path} />
      </div>
    </Swiper.Item>
  ))
  return (
    <>
      <div className="ad-area-cph">
        <Swiper
          autoplay={true}
          autoplayInterval={3000}
          loop={true}
        >
          {items}
        </Swiper>
      </div>
    </>
  )
}