/**
 * @author kkazzong
 * @desc BaseLayout
 */
import { SafeArea } from 'antd-mobile';
import { BaseFooter } from './BaseFooter';
import { BaseBody } from './BaseBody';
import { BaseHeader } from './BaseHeader';

export const BaseLayout = ({ header=true, footer=true, children }) => {

  return (
    // <div className='layout-content'>
    //   <div style={{ background: '#ffffff' }}>
    //     <SafeArea position='top' />
    //   </div>
    <>
      { header && <BaseHeader/> }
      <BaseBody header={header} footer={footer} >
        {children}
      </BaseBody>
      { footer&& <BaseFooter /> }
    </>
    //   <div style={{ background: '#ffffff' }}>
    //     <SafeArea position='bottom' />
    //   </div>
    // </div>
  );
};
