import client from "../axios"
import { POST_MEMBER_AGREE_TERMS, POST_MEMBER_RECOMMENDER } from "./endpoint"

export const postMemberRecommenderAPI = ({data}) => {
  return client.post(POST_MEMBER_RECOMMENDER, data);
}

export const postMemberAgreeTermsAPI = ({data}) => {
  return client.post(POST_MEMBER_AGREE_TERMS, data);
}
