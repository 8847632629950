import { AutoCenter } from "antd-mobile"
import { ClipLoader } from "react-spinners"

export const Loading = ({ loading }) => {
  return (
  // <AutoCenter style={{ marginTop:'84%'}}>
  <AutoCenter
    className="loading-component">
    <ClipLoader
      color='#9E9E9E'
      loading={loading}
      size={30}
    />
  </AutoCenter>
  )
}