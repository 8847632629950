import { useState } from 'react';
//import { Popup } from 'antd-mobile';
import { BaseButton, BaseImageButton } from 'components/elements';
import { MONTH_LABEL } from 'constants/Label';
import { getToday } from 'utils/utils';
import { Calendar } from 'components/elements/Calendar';
import { BottomPopUp } from './BottomPopUp';


const today = getToday('YYYYMMDD');
const curYear = getToday('YYYY');
const curMonth = getToday('MM');
const curDay = getToday('DD');

export const YearPickerModal = ({ selectedYYMM, onClose, onOk }) => {

  const selYear = selectedYYMM ? Number(selectedYYMM.slice(0, 4)) : Number(curYear)
  const selMonth = selectedYYMM ? Number(selectedYYMM.slice(-2)) : Number(curMonth)
  const [choice, setChoice] = useState({
    year: Number(selYear),
    month: Number(selMonth)
  });

  const fnPrevYear = () => {
    setChoice({
      ...choice,
      year: choice.year - 1
    });
  };

  const fnNextYear = () => {
    setChoice({
      ...choice,
      year: choice.year + 1
    });
  };

  const fnYearOk = () => {
    if (onOk) onOk(choice);
  }
  const fnHandleClose = () => {
    if (onClose) onClose();
  }
  const fnItemClick = (idx) => {
    let month = idx + 1;
    setChoice({
      ...choice,
      month
    })
  }

  return (
    <div className="adm-mask">
      <div className="adm-popup-body adm-popup-body-position-bottom">
        <div className="popup-bottom">
          <div className="popup-wrap year-picker-modal">
            <div className="popup-head popup-close">
              <strong>연도 선택</strong>
              <BaseImageButton imageSrc={"/assets/images/icon-close.svg"} altText={"닫기"} onClick={fnHandleClose}/>
            </div>
            <div className="popup-content">
              <div className="year-move">
                  <BaseImageButton onClick={fnPrevYear} imageSrc={"/assets/images/icon-year-arrow-left.svg"} altText={"왼쪽"}/>
                  <span>{choice.year}년</span>
                  <BaseImageButton onClick={fnNextYear} imageSrc={"/assets/images/icon-year-arrow-right.svg"} altText={"오른쪽"}/>
              </div>
              <div className="box-choice-list">
                <ul>
                  <li>
                    {
                      MONTH_LABEL.map((item, idx) =>
                        <div className="month-choice" onClick={() => fnItemClick(idx)} key={`month-choice-${idx}`}>
                          <input type="radio" name="check-use" className="check" checked={choice.month === (idx + 1) ? true: false}
                            onChange={(e) => {
                              // console.log(e)
                            }}
                          />
                          <label htmlFor="check-use">
                            {item}
                          </label>
                        </div>
                      )
                    }
                  </li>
                </ul>
              </div>
            </div>
            <div className="popup-button-wrap">
              <BaseButton label={"확인"} onClick={fnYearOk}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const CalendarPickerModal = ({ show, mask=true, position, bodyStyle, defaultDate, onOk, onClose}) => {
  const [yyChoice, setYyChoice] = useState(defaultDate? defaultDate.slice(0, 4) : curYear);
  const [mmChoice, setMmChoice] = useState(defaultDate? defaultDate.slice(0, 6).slice(-2) : curMonth);
  const [selectdDate, setSelectedDate] = useState(defaultDate || today); // 달력을 열때 선택한 날짜
  const [value, setValue] = useState(selectdDate); // 실제 달력에서 선택한 날짜 (최종)
  const [showYear, setShowYear] = useState(false);

  const fnShowYear = () => {
    setShowYear(true);
  }

  const fnHideYear = () => {
    setShowYear(false);
  }

  const fnYearOk = (yymmChoice) => {
    fnHideYear()
    setYyChoice(yymmChoice.year);
    setMmChoice(yymmChoice.month);
    // 1 ~ 9월 => 01 02 03
    let montStr = yymmChoice.month;
    if (montStr <= 9) montStr = `0${montStr}`
    setSelectedDate(`${yymmChoice.year}${montStr}${value ? value.slice(-2) :  selectdDate.slice(-2)}`)
  }

  const fnHandleClose = () => {
    if (onClose) onClose();
  }

  const fnHandleOk = () => {
    if (onOk) onOk(value);
  }

  const fnHandleMaskClick = () => {
    if (onClose) onClose();
  }

  return (
    <>
      {
        showYear && 
        <YearPickerModal 
          selectedYYMM={selectdDate.slice(0, 6)}
          onOk={fnYearOk} 
          onClose={fnHideYear}
        />
      }
      {
        !showYear && (
        // <Popup
        //   visible={show}
        //   mask={mask}
        //   position={position}
        //   bodyStyle={{
        //     ...bodyStyle,
        //     minHeight: '27rem',
        //   }}
        // > 
        <BottomPopUp
          show={show}
          onMaskClick={fnHandleMaskClick}
        >
          <div className="popup-bottom">
            <div className="popup-wrap account-book-write-popup">
              <div className="popup-head popup-close">
                <strong>날짜 선택</strong>
                <BaseImageButton imageSrc={"/assets/images/icon-close.svg"} altText={"닫기"} onClick={fnHandleClose}/>
              </div>
              <div className="popup-content">
                <div className="calendar-header2">
                  <div className="month-calendar">
                    <div className="month-switch-wrap">
                      <button className="btn-year" type="button" onClick={() => { fnShowYear() }}>{yyChoice}년 {mmChoice}월<img src="/assets/images/icon-calendar-arrow.svg" alt="" /></button>
                    </div>
                    <Calendar
                      defaultDate={selectdDate}
                      onChange={(value) => {
                        //console.log(`date value : ${value}`);
                        setValue(value);
                      }}
                  />
                  </div>
                </div> 
              </div>
              <div className="popup-button-wrap">
                <BaseButton label={"확인"} onClick={fnHandleOk}/>
              </div>
            </div>
          </div>
          </BottomPopUp>
        // </Popup>
        )
      }
    </>
  )
}