import {
  IconArrowUp,
  IconLevel,
  IconLike,
  IconLikeActive,
  IconMore,
  IconMoreReReply,
  IconReply,
} from 'components/assets/icon';
import { BaseImageButton, BaseTextarea } from 'components/elements';
import { WRITEREPLYPLEASE } from 'constants';
import { WRITEREPLY } from 'constants';
import {
  LIKE,
  REPLY,
  MORE,
  GRADE,
  NEWREPLYPLEASE,
  NOREPLY,
} from 'constants';
import { useCallback, useEffect, useRef, useState } from 'react';
import { gapTime } from 'utils/utils';
import { ProfileImage } from './ProfileImage';
import { BaseIconButton } from 'components/elements/BaseButton';
import { WRITER, MOREREPLY } from 'constants';
import { URL_LOGIN, URL_PROFILE } from 'routes/RouteUrl';
import { useNavigate } from 'react-router-dom';
import { DELETEDREPLY, BLOCKEDREPLY, COMPLAINEDREPLY, CLOSEREPLY, DELETEDREREPLY } from 'constants';
import { useAuth } from 'hooks/AuthContext';
import { AdvertisementArea } from './AdvertisementArea';
import { useMemberPlan } from 'hooks/useMemberPlan';
import { WITHDRAW_USER } from 'constants';

// blockList 추가 - 차단 이웃 목록
// showOnceList 추가 - 차단 이웃 - 한번만 보여줄 댓글 리스트
export const Comment = ({ dataList, onOk, onOpenMore, writerIdx, onLike, likeList, replyIdxParams, blockList, showOnceList }) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const userIdx = user?.user_idx;
  const { memberPlan } = useMemberPlan({ userIdx });
  const [focus, setFocus] = useState('blur');
  const textareaRef = useRef(null);
  const listRef = useRef(null)
  const [replyList, setReplyList] = useState([]);
  const [replyLikeList, setReplyLikeList] = useState([]);
  const [moreReplyList, setMoreReplyList] = useState({});   // 답글 더보기 판단을 위한 변수 {idx: true || false}
  const [replyWriteInfo, setReplyWriteInfo] = useState({
    content: '',
    replyType: 0,
    idx: 0,
    parentIdx: null
  });


  const fnClickWriteReply = (idx) => {
    setReplyWriteInfo({ ...replyWriteInfo, replyType: 1, parentIdx: idx });
    textareaRef?.current?.focus()
  }
  
  // 답글 더보기 클릭 시
  const fnHandleMoreReply = (idx) => {
    // 가져온 idx(replyList 의 인덱스) 에 true, false 를 지정해 줌
    setMoreReplyList((prev) => ({
      ...prev,
      [idx]: !prev[idx],
    }));
  };


  // 댓글 좋아요 클릭 시 
  const fnHandleReplyLike = (idx) => {
    if (userIdx) {
      // 회원이 좋아요 누른 댓글 리스트에 있는지 없는지 판단하는 flag 
      // 1: 좋아요 누른 댓글 리스트에 없음, -1: 좋아요 누른 댓글 리스트에 없음
      let flag = null;
      // type : 댓글과 게시글 좋아요 api 를 같이 씀 1: 게시글 2: 댓글
      const type = 2;
      // console.log(idx)
      if (onLike && replyLikeList) {
        // console.log(replyLikeList)
        if (!replyLikeList?.includes(idx)) {
          flag = 1;
        }
        else if (replyLikeList?.includes(idx)) flag = -1;
      }
      onLike({ type: type, idx: idx, flag: flag })
    } else if (!userIdx) navigate(URL_LOGIN, {replace: true})
  }


  // 프로필로 이동
  const fnGoProfile = (item) => {
    navigate(URL_PROFILE.replace(':id', item?.user_idx), {
      state: { info: item },
    });
  };


  // 댓글 포커스
  const fnHandleFocusOn = (e) => {
    setFocus(e.type);
  };


  // 댓글 신고
  const fnHandleReplyAction = (value) => {
    if (userIdx) {
      // console.log(value);
      onOpenMore({
        idx: value?.idx,
        replyWriter: value?.user_idx,
        modalType: 1,
      });
    } else if (!userIdx) navigate(URL_LOGIN, {replace: true})
  };


  // 댓글 쓰기
  const fnWriteReply = () => {
    if (userIdx) {
      const data = {
        replyType: replyWriteInfo?.replyType,
        idx: replyWriteInfo?.idx,
        content: replyWriteInfo?.content,
        parentIdx: replyWriteInfo?.parentIdx
      };
      onOk(data);
      setReplyWriteInfo({ content: '', replyType: 0, idx: 0, parentIdx: null });
    } else if (!userIdx) navigate(URL_LOGIN, {replace: true})
  };


  useEffect(() => {
    const subList = dataList?.filter(item => item.parent_idx !== 0);
    const mainList = dataList?.filter(item => item.parent_idx === 0);
    const filterList = mainList?.map(item => ({
      ...item,
      subList: subList?.filter(subItem => item.idx === subItem.parent_idx)
    }));
    setReplyList(filterList);
    setReplyLikeList(likeList);
    // console.log(filterList)
  }, [dataList, likeList]);


  useEffect(() => {
    // console.log(listRef?.current?.id);
    if (listRef?.current?.id) fnHandleMoreReply(listRef?.current?.id)
    listRef?.current?.scrollIntoView({ behavior: 'smooth' });
  }, [listRef, replyList, replyIdxParams]);


  const fnWritingReply = useCallback((value) => {
    setReplyWriteInfo((write) => ({
      ...write,
      ...value,
    }));
  }, []);

  return (
    <>
      <div className="repl-box">
        {replyList?.length < 1 ? (
          <div className="default">
            <p>
              {NOREPLY}
              <br />
              {NEWREPLYPLEASE}
            </p>
          </div>
        ) : (
          <div className="repl-list">
            <ul className="repl-info">
              {replyList?.map((item, idx) => (
                <li className={'repl'} key={`replyList_${idx}`} ref={item?.idx === replyIdxParams ? listRef : null}>
                  <div className="repl-box">
                    <div className="repl-header">
                      <div className="writer-box">
                        <div className="profile-horizental profile-img-42" onClick={() => fnGoProfile(item)}>
                          <ProfileImage
                            size={42}
                            imageSrc={item?.profile_img_path}
                          />
                          <div className="writer-info-wrap">
                            <div className="info">
                              {
                                !item.withdraw_date ? (
                                  <>
                                  <span className="nickname">
                                    {item?.user_nickname}
                                  </span>
                                  <span className="level">
                                    {item?.user_level_name}
                                  </span>
                                  <IconLevel
                                    level={item?.user_level}
                                    alt={`${item?.user_level}${GRADE} ${item?.user_level_name}`}
                                  />
                                  </>
                                ) : (
                                  <span className="nickname point-gr500">
                                    {WITHDRAW_USER}
                                  </span>
                                )
                              }
                              {writerIdx === item?.user_idx && 
                                <span className="label-l2">{WRITER}</span>
                              }
                            </div>
                            <div className="util">
                              <span className="time">
                                {gapTime(item?.create_date)}
                              </span>
                              {item?.myarea_sido} {item?.myarea_gugun}
                            </div>
                          </div>
                        </div>
                        <div className="more">
                          <BaseIconButton onClick={() => fnHandleReplyAction(item)}>
                            <IconMore altText={MORE} />
                          </BaseIconButton>
                        </div>
                      </div>
                    </div>
                    <div className="repl-content">
                      {!showOnceList?.includes(item?.idx) && blockList?.includes(item?.user_idx) ? (
                        <p className="delete">{BLOCKEDREPLY}</p>
                      ) : (
                        item?.status === 0 || item?.status === 2 
                        ? (<p>{item?.content}</p>)
                        : (
                          item?.status === 1 
                          ? (
                            (<p className="delete">{DELETEDREPLY}</p>)
                          ) : (
                            item?.status === 3  ? (
                              (<p className="delete">{COMPLAINEDREPLY}</p>)
                            ) : (
                              <p>{item?.content}</p>
                            )
                          )
                        )
                      )}
                    </div>
                    <div className="repl-util repl-util-pd">
                      <BaseIconButton
                        className={'repl-writing'}
                        onClick={() => fnClickWriteReply(item?.idx)}
                      >
                        <IconReply altText={REPLY} /> {WRITEREPLY}
                      </BaseIconButton>
                      <BaseIconButton className={userIdx ? (!replyLikeList?.includes(item?.idx) ? 'repl-like' : 'repl-like like-active') : 'repl-like'} onClick={() => fnHandleReplyLike(item?.idx)}>
                        { 
                          userIdx ? (
                            !replyLikeList?.includes(item?.idx) 
                              ? <IconLike altText={LIKE} />
                              : <IconLikeActive alt={LIKE} />
                            ) : <IconLike altText={LIKE} />
                        }
                        {LIKE}
                      </BaseIconButton>
                    </div>
                  </div>
                  {item.subList?.map((subItem, subIdx) => (
                    // moreReplyList[idx] 가 true 면 subIdx 는 0 아니면 subIdx 
                    // 필요한 이유 : 답글 더보기 버튼에 사용됨  subIdx 가 이면 답글 더보기 버튼 노출 아니면 답글 닫기 버튼 노출 
                    subIdx === (!moreReplyList[idx] ? 0 : subIdx) &&
                    <div className={'repl add-repl'} key={`reReply_${subIdx}`} id={idx} ref={subItem?.idx === replyIdxParams ? listRef : null}>
                      <div className="repl-box">
                        <div className="repl-header">
                          <div className="writer-box">
                            <div className="profile-horizental profile-img-32" onClick={() => fnGoProfile(subItem)}>
                              <ProfileImage
                                size={32}
                                imageSrc={subItem?.profile_img_path}
                              />
                              <div className="writer-info-wrap">
                                <div className="info">
                                  {
                                    !subItem?.withdraw_date ? (
                                      <>
                                        <span className="nickname">
                                          {subItem?.user_nickname}
                                        </span>
                                        <span className="level">
                                          {subItem?.user_level_name}
                                        </span>
                                        <IconLevel
                                          level={subItem?.user_level}
                                          alt={`${subItem?.user_level}${GRADE} ${subItem?.user_level_name}`}
                                        />
                                      </>
                                    ) : (
                                      <span className="nickname point-gr500">
                                        {WITHDRAW_USER}
                                      </span>
                                    )
                                  }
                                  {writerIdx === subItem?.user_idx && 
                                    <span className="label-l2">{WRITER}</span>
                                  }
                                </div>
                                <div className="util">
                                  <span className="time">
                                    {gapTime(subItem?.create_date)}
                                  </span>
                                  {subItem?.myarea_sido} {subItem?.myarea_gugun}
                                </div>
                              </div>
                            </div>
                            <div className="more">
                              <BaseIconButton onClick={() => fnHandleReplyAction(subItem)}>
                                <IconMore altText={MORE} />
                              </BaseIconButton>
                            </div>
                          </div>
                        </div>
                        <div className="repl-content">
                        {!showOnceList?.includes(subItem?.idx) && blockList?.includes(subItem?.user_idx) ? (
                          <p className="delete">{BLOCKEDREPLY}</p>
                        ) : (
                          subItem?.status === 0 || subItem?.status === 2
                          ? (<p>{subItem?.content}</p>)
                          : (
                            subItem?.status === 1 
                              ? (
                                (<p className="delete">{DELETEDREREPLY}</p>)
                              ) : (
                                subItem?.status === 3 ? (
                                  (<p className="delete">{COMPLAINEDREPLY}</p>)
                                ) : (
                                  <p>{subItem?.content}</p>
                                )
                              )
                            )
                        )}
                        </div>
                        <div className="repl-util repl-util-pd">
                          <BaseIconButton className={userIdx ? (!replyLikeList?.includes(subItem?.idx) ? 'repl-like' : 'repl-like like-active') : 'repl-like'} onClick={() => fnHandleReplyLike(subItem?.idx)}>
                            { 
                              userIdx ? (
                                !replyLikeList?.includes(subItem?.idx) 
                                  ? <IconLike altText={LIKE} />
                                  : <IconLikeActive alt={LIKE} />
                                ) : <IconLike altText={LIKE} />
                            }
                            {LIKE}
                          </BaseIconButton>
                        </div>
                      </div>
                      {
                        item.subList.length > 1
                        && subIdx === 0 
                        && !moreReplyList[idx] && 
                          <BaseIconButton
                            className={'repl-more'}
                            onClick={() => fnHandleMoreReply(idx)}
                          >
                            <IconMoreReReply altText={MOREREPLY} /> {MOREREPLY}
                          </BaseIconButton>
                      }
                      {
                        item.subList.length > 1 
                        && moreReplyList[idx] 
                        && subIdx === item.subList.length - 1 && 
                          <BaseIconButton
                            className={'repl-more'}
                            onClick={() => fnHandleMoreReply(idx)}
                          >
                            <IconArrowUp altText={CLOSEREPLY} /> {CLOSEREPLY}
                          </BaseIconButton>
                      }
                    </div>
                  ))}
                </li>
              ))}
            </ul>
          </div>
        )}
        { !memberPlan?.planYn && replyList?.length > 0 &&
          <AdvertisementArea className={'h92'} />
        }
      </div>

      <div className="repl-wrap">
        <BaseTextarea
          ref={textareaRef}
          placeholder={WRITEREPLYPLEASE}
          onBlur={fnHandleFocusOn}
          onFocus={fnHandleFocusOn}
          onChange={(value) => fnWritingReply({ content: value })}
          value={replyWriteInfo?.content}
          maxLength={200}
        ></BaseTextarea>
        <BaseImageButton
          imageSrc={`../assets/images/icon-16-${focus}.svg`}
          altText={WRITEREPLY}
          onClick={fnWriteReply}
        />
      </div>
    </>
  );
};

