import { useState, useRef, useEffect } from 'react';
import { Swiper, PageIndicator, Image } from 'antd-mobile';
import { SLIDE, NEEDHOME, HOME, JOIN1, JOIN2, JOIN3, JOIN4, JOIN5, JOIN6, JOIN7, JOIN8 } from 'constants';
import { Loading} from 'components/containers';
import { useSwipeable } from 'react-swipeable';
import { useAuth } from 'hooks/AuthContext';
import { useNavigate } from 'react-router-dom';
import { URL_HOME } from 'routes/RouteUrl';
import { AGENT } from 'constants/Mobile';
import { BaseButton } from 'components/elements';
import { $get, $getIOS } from 'utils/mobile';

const DemoDescription = ({ children }) => (
  <div className="slide-dots">
    <ul>{children}</ul>
  </div>
);

export const JoinIntro = ({ onMoveStep, common }) => {
  const { user, login } = useAuth();
  const navigate = useNavigate();
  const AGENT_TYPE = common.agentType;
  const title = [
    <>{HOME}!<br />{NEEDHOME}</>,
    <>{JOIN1}<br />{JOIN2}</>,
    <>{JOIN3}<br />{JOIN4}</>,
    <>{JOIN5}<br />{JOIN6}</>,
    <></>
  ];
  const pcTitle = [<>{JOIN7}<br />{JOIN6}</>]

  const items = title?.map((title, index) => (
      <Swiper.Item key={index}>
        <div className={'slide slide-1'}>
          <div className="slide-img">
            <img
              src={`assets/images/img-1-${index + 1}.png`}
              alt={`${SLIDE} ${index + 1}`}
              draggable={false}
            />
          </div>
          <p>{title}</p>
        </div>
      </Swiper.Item>
  ));

  const swiperRef = useRef(null);
  const [page, setPage] = useState(0);
  const [isShow, setShow] = useState({ 
    loading: true,
  });

  // 로딩 토글
  const fnToggleLoading = (value) => {
    setShow({ ...isShow, loading: value })
  }

  const fnHandleIndex = (i) => {
    setPage(i);
  };

  const onSwipedLeft = () => {
    if (AGENT_TYPE !== AGENT.PC && page === title.length -2) onMoveStep()
  };

  const handlers = useSwipeable({
    onSwipedLeft: (data) => onSwipedLeft(data),
    swipeDuration: 500,
    trackMouse: true, // fix safari issue
  });

  useEffect(() => {
    // IOS 앱일 때
    if (AGENT_TYPE === AGENT.IOS_APP) {
      $getIOS('userToken', (userToken) => {
        $getIOS('userIdx', (userIdx) => {
          if (userToken && userIdx) {
            login({token: userToken, user_idx: userIdx});
            navigate(URL_HOME);
          }
          fnToggleLoading(false);
        })
      })

    // 안드로이드 앱일 때
    } else if (AGENT_TYPE === AGENT.ANDROID_APP) {
      const userToken = $get('userToken');
      const userIdx = $get('userIdx');
      if (userToken && userIdx) {
        login({token: userToken, user_idx: userIdx});
        navigate(URL_HOME);
      }
      fnToggleLoading(false);
    } else if (user?.token && user?.user_idx) {
      navigate(URL_HOME);
      fnToggleLoading(false);
    } else {
      fnToggleLoading(false);
    }
  }, [])

  return (
    <>
    { !isShow?.loading &&
        <div className="wrap wrap-join">
          <div className="member">
            <div className="main-slide">
              <div className="slide-wrap">
                <div className="slide-content">
                  { AGENT_TYPE === AGENT.PC && page === title?.length -1 && 
                    <div className="slide-pc-title">{pcTitle}</div>
                  }
                  <div {...handlers}>
                  <Swiper
                    onIndexChange={(i) => fnHandleIndex(i)}
                    ref={swiperRef}
                  >
                    {items}
                  </Swiper>
                  </div>
                  { page !== title?.length -1 &&
                    <DemoDescription>
                      <PageIndicator
                        total={title?.length -1}
                        current={page}
                        style={{
                          '--dot-color': '#EAEEED',
                          '--active-dot-color': '#40DF9F',
                          '--dot-size': '8px',
                          '--active-dot-size': '8px',
                          '--dot-border-radius': '50%',
                          '--active-dot-border-radius': '15px',
                          '--dot-spacing': '8px',
                        }}
                      />
                    </DemoDescription>
                  }
                </div>
                { AGENT_TYPE === AGENT.PC && page === title?.length -1 &&
                  <div>
                    <BaseButton 
                      label={JOIN8}
                      onClick={onMoveStep}
                    />
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      }
      {/* 로딩바 */}
      <Loading loading={isShow?.loading}/>
      </>
  );
};

