import { DEFAULT_PROFILE_PATH } from 'constants';
import { PROFILE_MAX } from 'constants';
import { LEVEL1, LEVEL2, LEVEL3, LEVEL4, LEVEL5, LEVEL6, LEVEL7, LEVEL8, LEVEL9 } from 'constants';
import moment from 'moment';

/**
 * 안씀 삭제예정
 */
export const checkAgent = () => {
  const agent = navigator.userAgent;
  if (agent.match(/iPhone/i)) return 'IOS';
  else if (agent.match(/Android/i)) return 'AND';
  else return 'WEB';
}


/**
 * 오늘 날짜 계산
 * Input
 * opts
 * delim : delimeter
 */
export const getToday = (opts, delim, timeDelim) => {
  //const d = moment(new Date()); // 아이폰 사파리의 경우 new Date 안됨
  const d = moment(); 

  let yyyy = d.format('YYYY');
  let mm = d.format('MM');
  let dd = d.format('DD');
  let hh = d.format('HH');
  let min = d.format('mm');
  let ss = d.format('ss');
  let ms = d.format('SSS');

  if (delim === undefined) delim = '';
  if (timeDelim === undefined) timeDelim = '';

  if (opts === 'YYYY') {
    return yyyy;
  } else if (opts === 'MM') {
    return mm;
  } else if (opts === 'DD') {
    return dd;
  } else if (opts === 'HHMM') {
    return [hh, min].join(delim);
  } else if (opts === 'YYYYMMDD') {
    return [yyyy, mm, dd].join(delim);
  } else if (opts === 'YYYYMMDDHHMMSS') {
    //return [yyyy, mm, dd, hh, min, ss].join(delim);
    return `${[yyyy, mm, dd].join(delim)} ${[hh, min, ss].join(timeDelim)}`;
  } else if (opts === 'YYYYMMDDHHMMSSMS') {
    return [yyyy, mm, dd, hh, min, ss, ms].join(delim);
  }

  return '';
};
/**
 * 날짜 포맷
 * date : 날짜
 * opts
 * delim : delimeter
 * timeDelim : 시간 구분자
 */
export const formatDate = (date, opts, delim, timeDelim) => {
  //const d = moment(new Date(date));
  const d = moment(date);

  let yyyy = d.format('YYYY');
  let mm = d.format('MM');
  let dd = d.format('DD');
  let hh = d.format('HH');
  let min = d.format('mm');
  let ss = d.format('ss');
  let ms = d.format('SSS');

  if (delim === undefined) delim = '';
  if (timeDelim === undefined) timeDelim = '';

  if (opts === 'YYYY') {
    return yyyy;
  } else if (opts === 'MM') {
    return mm;
  } else if (opts === 'DD') {
    return dd;
  } else if (opts === 'HHMM') {
    return [hh, min].join(delim);
  } else if (opts === 'YYYYMM') {
    return [yyyy, mm].join(delim);
  } else if (opts === 'YYYYMMDD') {
    return [yyyy, mm, dd].join(delim);
  } else if (opts === 'YYYYMMDDHHMM') {
    return `${[yyyy, mm, dd].join(delim)} ${[hh, min].join(timeDelim)}`;
  } else if (opts === 'YYYYMMDDHHMMSS') {
    return `${[yyyy, mm, dd].join(delim)} ${[hh, min, ss].join(timeDelim)}`;
  } else if (opts === 'YYYYMMDDHHMMSSMS') {
    return [yyyy, mm, dd, hh, min, ss, ms].join(delim);
  }

  return '';
};
/**
 * string -> YYYY delim MM delim DD 형식으로 변환
 * str : 문자열
 * delim : delimeter
 * opt : 'YYYYMM'
 */
export const convertStr = (str, delim, opt) => {
  if (str) {
    const year = str.substr(0, 4);
    const month = str.substr(4, 2);
    const day = str.substr(6, 2);
    if (opt === 'YYYYMM')
      return `${year}${delim}${month}`;
    else
      return `${year}${delim}${month}${delim}${day}`;
  }
  return '';
}
/**
 * string -> YYYY년 MM월 DD일 형식으로 변환
 * str : 문자열
 */
export const convertDateStr = (str, delim) => {
  if (str && str.indexOf('Invalid') === -1) {
    const year = str.substr(0, 4);
    const month = str.substr(4, 2);
    const day = str.substr(6, 2);
    return `${year}년 ${month}월 ${day}일`;
  }
  return '- ';
}
export const removeDelim = (str, delim) => {
  return str.replaceAll(delim, '');
}
/**
 * 12시간 -> 24시간 변환
 * period : 0 - 오전 / 1 - 오후
 * PM 04: 30 -> 16 : 30
 */
export const convert12TimeTo24 = ({ period, hour, min }) => {
  if (period === 1) {
    return { hour: (Number(hour) + 12).toString(), min }
  } else {
    return { hour, min }
  }
}
/**
 * 24시간 -> 12시간 변환
 * period : 0 - 오전 / 1 - 오후
 * 16 : 30 -> PM 04: 30
 */
export const convert24TimeTo12 = ({ hour, min }) => {
  const hourNum = Number(hour);
  if (hourNum >= 12) {
    return { periodIdx: 1, hour: hourNum === 12 ? 12 : hourNum - 12 <= 9 ? `0${(hourNum - 12)}` : hourNum - 12, min }
  } else {
    return { periodIdx: 0, hour, min }
  }
}
/**
 * 시간순 정렬
 */
export const sortAscByDate = (arr, key) => {
  return arr.sort((a, b) => {
    return new Date(a[key]) - new Date(b[key]);
  })
}
export const sortDescByDate = (arr, key) => {
  return arr.sort((a, b) => {
    return new Date(b[key]) - new Date(a[key]);
  })
}
/**
 * n 개월 후
 * date : 기준날짜
 * 2023-03-02 13:22:00 -> 2024-04-02 13:22:00
 * 결제 시 쓰임
 */
export const getMonthNext = (date, next) => {
  const currentDate = new Date(date);

  const monthsNext = new Date(currentDate);
  monthsNext.setMonth(currentDate.getMonth() + (next));

  return monthsNext;
}
/**
 * n 개월 전
 * date : 기준날짜
 * 
 */
export const getMonthAgo = (date, ago) => {
  const currentDate = new Date(date);

  const monthsAgo = new Date(currentDate);
  monthsAgo.setMonth(currentDate.getMonth() - (ago - 1));

  return monthsAgo.getMonth() + 1; 
}
/**
 * n 개월 전 (array)
 * date : 기준날짜
 * ['2023-11', '2023-12', '2024-01']
 */
export const getMonthAgoArr = (date, ago) => {
  const months = [];
  const currentDate = new Date(date);

  const monthsAgo = new Date(currentDate);
  monthsAgo.setMonth(currentDate.getMonth() - (ago - 1));

  // console.log(monthsAgo, currentDate)

  while (monthsAgo <= currentDate) {
    const nMonthsAgoMonth = monthsAgo.getMonth() + 1;
    const nMonthsAgoYear = monthsAgo.getFullYear();
  
    months.push(`${nMonthsAgoYear}-${nMonthsAgoMonth <= 9 ? '0'+nMonthsAgoMonth: nMonthsAgoMonth}`);
  
    monthsAgo.setMonth(monthsAgo.getMonth() + 1); // 한 달씩 증가
  }

  return months;
}

/**
 * 시간 경과 계산
 * date : 날짜
 */
export const gapTime = (date) => {
  const current = moment(new Date());
  const d = moment(new Date(date));

  const diff = {
    day: moment.duration(current.diff(d)).days(),
    hour: moment.duration(current.diff(d)).hours(),
    min: moment.duration(current.diff(d)).minutes(),
    second: moment.duration(current.diff(d)).seconds()
  }
  
  let rslt;
  const day = diff.day;
  const hour = diff.hour;
  const min = diff.min;
  const second = diff.second;
  if (day && day > 0) {
    if (day >= 1)
      return formatDate(date, 'YYYYMMDD', '.')
      //rslt = '어제';
    // else if (day >= 2 && day <= 6)
    //   rslt = `${day}일 전`;
    // else if (day === 7)
    //   rslt = '일주일 전';
    // else if (day > 7)
    //   rslt = `${day}일 전`;
  } else if (hour && hour > 0) {
    rslt = `${hour}시간 전`
  } else if (min && min > 0) {
    rslt = `${min}분 전`
  } else if (second === 0 || second) {
    rslt = `${second === 0 ? 1 : second}초 전`
  }
  return rslt;
}
/**
 * 랜덤 스트링 생성(영문대문자)
 * n : 자리수
 */
export const randomAlphaCapital = (n) => {
  if (n < 2) return '';

  let value = '';
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

  for (let i = 0; i < n; i++) {
    value += chars.charAt(Math.floor(Math.random() * chars.length));
  }

  return value;
};
/**
 * 랜덤 스트링 생성(숫자)
 * n : 자리수
 * min : 최소 , max: 최대
 */
export const randomNumeric = ({n, min=0, max=10}) => {
  //if (n < 2) return '';

  let value = '';
  //const chars = '0123456789';

  // for (let i = 0; i < n; i++) {
  //   value += chars.charAt(Math.floor((Math.random() * (max - min)) + min));
  // }
  value = Math.floor((Math.random() * (max - min)) + min);

  return value;
};
/**
 * 프로필 랜덤 생성
 * DEFAULT_PROFIL_PATH를 랜덤한 숫자(1부터 ~ PROFILE_MAX까지)로 뽑아서 #{random} 부분을 치환해줌
 * 예 ) /assets/images/profile-icon-17.svg
 */
export const getRandomProfilePath = () => {
  return DEFAULT_PROFILE_PATH.replace('#{random}', randomNumeric({ n:2, min:1, max: Number(PROFILE_MAX) }))
}

export const sleep = (seconds) => new Promise((resolve) => setTimeout(resolve, seconds));

/**
 * 금액 절삭 (단위)
 * num : 금액숫자
 * unit : 단위 
 * 예: 단위 - 10000 인 경우, 10만원 -> 10 / 5000원 -> 0.5
 */
export const getAmountDivision = (num, unit) => {
  let value = num;
  if (value) {
    const division = num / unit;
    const numStr = division.toString();

    const parts = numStr.split('.');
    if (parts[0] === '0') {
      const decimalPart = numStr.split('.')[1].slice(0, 1);
      return `${parts[0]}.${decimalPart}`;
    } else {
      return parts[0];
    }
  } else {
    return value;
  }
};

/**
 * 숫자 콤마 표시
 * number : 숫자
 * decimalPeriod : 소숫점 표시
 */
function formatNumberWithCommas(number, decimalPeriod) {
  
  const numStr = number.toString();

  const parts = numStr.split('.');

  const hasDecimal = numStr.includes('.');

  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  if (hasDecimal) {
    if (!decimalPeriod) decimalPeriod = 1;
    const decimalPart = numStr.split('.')[1].slice(0, decimalPeriod);
    return `${parts[0]}.${decimalPart}`;
  } else {
      return parts.join('.');
  }
};

/**
 * 금액 콤마 표시
 * num : 금액숫자
 */
export const getAmountWithComma = (num) => {
  let value = num;
  if (value) {
    return formatNumberWithCommas(value);
  } else {
    return value;
  }
};

/**
 * 금액 콤마 표시 + 단위
 * num : 금액숫자
 * delim : KRW - 원, USD - 달러 등
 */
export const getAmountWithCommaDelim = (num, delim) => {
  let value = num;
  let delimStr = '';
  if (value) {
    value = formatNumberWithCommas(value);
  }

  if (delim) {
    switch (delim) {
      case 'KRW':
        delimStr = '원';
        break;
      case 'USD':
        delimStr = '달러';
        break;
      default:
        delimStr = '원';
        break;
    }
    return `${value}${delimStr}`;
  } else {
    return value;
  }
};

/**
 * 사용량 계산
 * total : 전체 
 * amt : 부분 
 */
export const calcPercent = (amt, total) => {
  let value = 0;
  if (total && total > 0) {
    value = (amt / total) * 100;
  }
  const ret = formatNumberWithCommas(value);

  return `${ret}%`;
};

/**
 * 복사
 * v : 복사할 텍스트 
 */
export const copy = (v) => {
  if (v) {
    return new Promise((resolve, reject) => {
      if (navigator.clipboard) {

        // clipboard copy
        navigator.clipboard.writeText(v)
            .then(() => {
              //alert('복사되었습니다.')
              resolve(true);
            })
            .catch(err => {
              reject(err)
            })

      } else {
        
        // document copy
        let el = document.createElement('textarea');
        el.value = v;
        document.body.appendChild(el);
        el.select();

        try{
          const rslt = document.execCommand('copy');
          resolve(rslt);
        } catch(err) {
          reject(err)
        } finally {
          document.body.removeChild(el);
        }

      }
    })
  }
}


/**
 * 공유
 * title: 공유 제목, text : 공유 설명, url : 공유 url 
 */
export const share = ({ title, text, url }) => {
  if (url) {
    return new Promise((resolve, reject) => {
      if (window.navigator.share) {
        window.navigator.share({
          title: title, // 공유될 제목
          text: text, // 공유될 설명
          url: url, // 공유될 URL
          //files: [], // 공유할 파일 배열
        }).then(() => {
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        })
      }
    })
  }
}

/**
 * 지역 관련 유틸 - 지역 자르기
 * text : 텍스트 (예: 서울시 금천구)
 */
export const sliceArea = (text) => {
  const rslt = {
    sido: '',
    gungu: '',
  };
  if (text) {
    const regx = text.split(/\s/g);
    if (regx) {
      rslt.sido = regx[0];
      rslt.gungu = regx[1];
    }
  }
  // console.log(rslt)
  return rslt;
}

/**
 * 쿠키 관련 유틸 - 쿠키 설정
 * name : 쿠키 이름
 * value : 쿠키 값
 * days : 유효 기간
 */
export const setCookie = (name, value, days) => {
  const expires = moment().add(days, 'days').toDate()?.toUTCString();
  const cookie = `${name}=${value};expires=${expires};path=/`;
  document.cookie = cookie;
};

/**
 * 쿠키 관련 유틸 - 쿠키 가져오기
 * name : 쿠키 이름
 */
export const getCookie = (name) => {
  const cookie = document.cookie.split('; ').find(cookie => cookie.startsWith(`${name}=`));
  return cookie ? cookie.split('=')[1] : null;
};

/**
 * 쿠키 관련 유틸 - 모달 show 여부 결정
 */
export const isShowImageModal = (name) => {
  const cookie = getCookie(name);
  return !cookie;
};

/**
 * 회원 등급 (안씀 삭제예정)
 */
export const getUserLevel = (level) => {
  let levelStr = '';
  if (level) {
    const levelNum = Number(level);
    switch (levelNum) {
      case 1:
        levelStr = LEVEL1;
        break;
      case 2:
        levelStr = LEVEL2;
        break;
      case 3:
        levelStr = LEVEL3;
        break;
      case 4:
        levelStr = LEVEL4;
        break;
      case 5:
        levelStr = LEVEL5;
        break;
      case 6: //기고가
        levelStr = LEVEL6;
        break;
      case 7: //보호소
        levelStr = LEVEL7;
        break;
      case 8: //스텝
        levelStr = LEVEL8;
        break;
      case 9: //고인물
        levelStr = LEVEL9;
        break;
      default:
        levelStr = LEVEL1;
        break;
    }
  }
  return levelStr;
}

/**
 * 해시태그 파싱
 */
export const parseHashtags = (str) => {
  const delim = '#';
  if (str && str.includes(delim)) {
    const filterdArr = str.split('#').filter(item => item.length > 0).map(item => `${delim}${item}`)
    return filterdArr;
  }
  return []
}

/**
 * 문자열 -> html 변환 (안씀)
 */
export const convertStrToHtml = (str) => {
  if (str && str.length > 0) {
    return str.replaceAll(/\\n/g, '<br>');
  }
  return ''
}

/**
 * 문자열 ellipseStr
 * str : 원본문자 
 * len : 줄일 길이
 * delim : 줄일 문자열 / 생략시 ・・・
 */
export const ellipseStr = (str, len, delim) => {
  if (!delim) delim = '...';
  if (str)
    if (str.length >= 8) 
      return `${str.substring(0, len)}${delim}`
    else  
      return str
  else 
    return ''
}

/**
 * 문자열 newLineStr
 * str : 원본문자 
 * len : 엔터 칠 길이
 */
export const newLineStr = (str, len) => {
  if (str)
    if (str.length > len) {
      let result ='';
      for (let i = 0; i < str.length; i++) {
        result += str.charAt(i);
        if ((i + 1) % len === 0 && i !== str.length - 1) {
            result += '<br/>';
        }
      }
      return result;
    }
    else  
      return str
  else 
    return ''
}

/**
 * 주소 자르기
 * 경기도 성남시 분당구
 * sido : 경기도
 * gugun : 성남시 분당구
 */
export const parseAddr = (str) => {
  if (str && str.length > 0) {
    const part = str.split(' ')
    const sido = part[0];
    const notsido = str.replace(sido, '').trim().split(' ')
    const gugun = notsido[0];
    return { sido, gugun }
  }
  return str
}

/**
 * 계산식 - 계산하기
 * 100+300-40 -> 360 return
 */
export const calc = (str) => {
  // [100, +, 300, -, 40]
  let result = str
  if (typeof str === 'string') { // 모바일 대응...
    
    result = result.replaceAll('×', '*');
    result = result.replaceAll('÷', '/');
    const tokens = result.match(/\d+|\+|\-|\*|\//g); 
    for (let i = 0; i < tokens.length; i++) {
      if (tokens[i] === '*' || tokens[i] === '/') {
        const operator = tokens[i];
        let leftOperand = parseFloat(tokens[i - 1]);
        let rightOperand = parseFloat(tokens[i + 1]);
        if (!leftOperand) leftOperand = 1;
        if (!rightOperand) rightOperand = 1;
        // 연산 수행
        const result = (operator === '*') ? leftOperand * rightOperand : leftOperand / rightOperand;
  
        // 결과를 배열에 반영
        tokens.splice(i - 1, 3, result);
        i--; // 인덱스 조정
      }
    }
  
    // 남은 덧셈과 뺄셈 처리
    result = parseFloat(tokens[0]);
    for (let i = 1; i < tokens.length; i += 2) {
      const operator = tokens[i];
      let operand = parseFloat(tokens[i + 1]);
      if (!operand) operand = 0;
      //console.log(operand)
      // 연산 수행
      if (operator === '+') {
          result += operand;
      } else if (operator === '-') {
          result -= operand;
      }
    }
  }
  // eslint-disable-next-line no-undef
  return BigInt(result);
}

/**
 * 
 * @param {*} str (JSON)
 * @returns 
 */
export function IsJsonParse(str) {
  try {
    const json = JSON.parse(str);
    if (typeof json === 'object') {
      return json
    }
  } catch (e) {
    return str;
  }
}

/**
 * @param origin
 * @param pathname
 * pathname 암호화
 */
export function makeLink({ origin, pathname, query=undefined }) {
  if (origin && origin !== '') {
    if (pathname && pathname !== '') {
      // const arr = origin.split('/')
      // const scheme = arr[0] // http: https:
      // const hostName = arr[2] // localhost:3000

      //const encodedPathname = btoa(pathname)
      let link = `${origin}/link${pathname}`
      if (query) {
        const keys = Object.keys(query)
        let querystring = ''
        for(const item of keys) {
          querystring += `${item}=${query[item]}`
        }
        link = `${origin}/link${pathname}?${querystring}`
      } 
      return link
    } else {
      return origin
    }
  }
}

export function forwardLink({ url, origin, pathname }) {
  if (url && origin && origin !== '') {
    if (pathname && pathname !== '') {
      const arr = url.split(origin)
      const originalPath = arr[1].split('/link')[1]
      //const arr = pathname.split('/link')
      // console.log(`${origin}${originalPath}`)
      return `${originalPath}`
    } else {
      return origin
    }
  }
}

/**
 * @param url
 * pathname 복호화
 */
// export function decodeLink(url) {
//   if (url) {
//     const protocol1 = 'http://';
//     const protocol2 = 'https://';
//     const origin = url.replace(protocol1, '').replace(protocol2, '').split('/')?.[0]
//     console.log(`origin ${origin}`)
//     const pathname = url.replace(protocol1, '').replace(protocol2, '').split(origin)?.[1]?.replace('/', '')
//     console.log(`pathname ${pathname}`)

//     if (pathname) {
//       const decodedPathname = atob(pathname)
//       console.log(`decode : ${decodedPathname}`)
//       console.log(`http://${origin}${decodedPathname}`)
//     }
//   }
//   //return url
// }