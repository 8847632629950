import { Popup } from 'antd-mobile';
import { PC_MAX_WIDTH } from 'constants';
import styled from 'styled-components';

const PopupWrapper = styled(Popup)`
  @media (min-width: ${PC_MAX_WIDTH}) {
    /* pc 에서 ant design Popup 하단 가운데 정렬 */
    .adm-popup-body {
      position: fixed;
      background-color: var(--adm-color-background);
      z-index: calc(var(--z-index) + 10);
      transform: translate(-50%, 0%) !important;
    }
    .adm-popup-body-position-bottom {
      width: 100%;
      /* top: 50%; */
      left: 50%;
      max-width: ${PC_MAX_WIDTH};
    }     
  }
`

/**
 * 상단(헤더 밑)에 위치하는 Modal
 * hasBottom : Bottom 메뉴 포함 여부 (하단 탭 hiehgt인듯!!)
 * @returns 
 */
export function BottomPopUp({ label = '', show, hasBottom = false, children, onMaskClick }) {

  let style = {
    // padding: '24px',
    borderRadius: '2.4rem 2.4rem 0 0',
  };

  if (hasBottom) {
    style = { ...style, marginBottom: 'var(--bottom-height)' };
  }
  return (
    <PopupWrapper
      label={label}
      visible={show}
      mask={true}
      bodyStyle={{ ...style }}
      position='bottom'
      onMaskClick={onMaskClick}
    >
        {children}
    </PopupWrapper>
  );
};