import { Outlet } from "react-router-dom";
import { SafeArea } from 'antd-mobile';

export const AuthLayout = ({ common }) => {

  return (
    <div className='layout-content'>
      <div style={{ background: '#ffffff' }}>
        <SafeArea position='top' />
      </div>
      <Outlet/>
      <div style={{ background: '#ffffff' }}>
        <SafeArea position='bottom' />
      </div>
    </div>
  )
}