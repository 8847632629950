import { NetworkError } from "pages/common/NetworkError";
import { useEffect, useState } from "react";

export function BaseBody({ header, footer, children }) {

  const [onLine, setOnLine] = useState(true);

  useEffect(() => {
    // 오프라인 확인
    const onLine = window.navigator.onLine;
    setOnLine(onLine)
  }, [])
  return (
    <>
      {
        onLine ? (
          <div className={`layout-body ${!header&&'no-header'} ${!footer&&'no-footer'}`}>
            <div className={'layout-scroll-body'}>
              {children}
            </div>
          </div>
        ) : (
          <NetworkError/>
        )
      }
    </>
  );
}
