import { useInterval } from "hooks/useInterval"
import { useEffect, useState } from "react";

export const AdvertisementArea = ({ className='', type='default' }) => {

  useEffect(() => {
    /*
    const pushAd = () => {
      try {
        const adsbygoogle = window.adsbygoogle
        // console.log({ adsbygoogle })
        adsbygoogle.push({})
      } catch (e) {
        console.error(e)
      }
    }

    let interval = setInterval(() => {
      // Check if Adsense script is loaded every 300ms
      if (window.adsbygoogle) {
        pushAd()
        // clear the interval once the ad is pushed so that function isn't called indefinitely
        clearInterval(interval)
      }
    }, 300)

    return () => {
      clearInterval(interval)
    }
    */
  }, [])

  const AdvertiseBox = () => {
    if (type === 'default') {
      return (//수평형 - 예) 홈화면
        <ins 
          className="adsbygoogle"
          style={{ display: 'block' }}
          data-ad-client={process.env.REACT_APP_AD_KEY}
          data-ad-slot="3095182952"
          data-ad-format="auto"
          data-full-width-responsive="true"
        ></ins>
      )
    } else {
      return (//정사각형 - 예) 커뮤니티 리스트
        <ins 
          className="adsbygoogle"
          style={{ display: 'block' }}
          data-ad-client={process.env.REACT_APP_AD_KEY}
          data-ad-slot="7029137655"
          data-ad-format="auto"
          data-full-width-responsive="true"
        ></ins>
      )
    }
  } 

  return (
    <div className={`ad-area ${className}`}>
      <AdvertiseBox/>
    </div>
    /* 검색 시 있는 광고 배너
      <div className="search-banner" >
      <a href="#">
          <img src="" alt="" />
      </a>
    </div>
    */
  )
}