import { $permission, $permissionIOS, $requestPermission } from "utils/mobile"

/**
 * 
 * 앱 접근 권한 확인
 * (카메라, GPS 등)
 * accessType은 AccessPermissionPopup 참고
 */
export const useAccessPermission = () => {

  // 앱 접근 권한 확인
  const checkAccess = ({ type }) => {
    const permission = $permission({ type });
    // // if (permission) {
    // //     // 접근권한 있는 경우
    // //     //fnOpenChoiceModal()
    // // } else {
    // //     // 접근권한 없는 경우
    // //     //fnOpenAccessModal()
    // // }
    return permission;
  }

  const checkAccessIOS = ({ type }) => {
    // alert('1 checkAccess IOS')
    return new Promise((resolve, reject) => {
      try {
        $permissionIOS({ type })
          .then((res) => {
            resolve(res)
          })
      } catch (err) {
        reject(err)
      }
    })
  }

  // 앱 접근 권한 요청
  const requestAccess = ({ type }) => {
    const requestPermission = $requestPermission({ type });
    return requestPermission;
  }

  return { checkAccess, checkAccessIOS, requestAccess }

  
}
