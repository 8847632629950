import { Link } from "react-router-dom";

export function BaseLink({className='', children, to=null, state=null, onClick=undefined, target=''}) {
  const fnHandleBack = () => {
    if (onClick) onClick()
  }
  return (
    <Link to={to} state={state} onClick={fnHandleBack} className={className} target={target}>{children}</Link>
  )
}

export function BaseImageLink({to=null, className='', onClick=undefined, imageSrc, altText}) {
  const fnHandleBack = () => {
    if (onClick) onClick()
  }
  return (
    <Link to={to} className={className} onClick={fnHandleBack}><img src={imageSrc} alt={altText} /></Link>
  )
}

