export const GET_SAMPLE_LIST = '/api/sample/list';
export const GET_SAMPLE = '/home/main';
export const POST_SAMPLE = '/api/sample';
export const POST_FILE = '/common/saveFile/:action_type/:user_idx/:content_idx';
export const GET_USER_INFO = '/common/userInfo/:user_idx';
export const GET_GOODS_LIST = '/common/goodsList'; //상품
export const GET_FAQ_LIST = '/common/faqList';
export const GET_FAQ = '/common/faqInfo/:faq_idx';
export const GET_NOTE_SEARCH = '/common/search'; //수첩검색
export const GET_PUSH_LIST = '/common/push_history/:user_idx'; //푸시목록
export const GET_POPUP_LIST = '/common/popupList';
export const POST_TOKEN = '/common/token';
export const POST_PURCHASE_TOKEN = '/common/purchase_token';

export const GET_MAIN_LIST = '/front/home/main';
export const GET_MONTHLY_LIST = '/front/home/homeList';
export const GET_POPULAR_LIST = '/front/home/popularList';
export const GET_COMEPETHOME_SELECT_LIST = '/front/home/selectList';
export const GET_TODAY_LIST = '/front/home/today';
export const PUT_MONTHLY_VIEW_CNT = '/front/home/monthlyCnt/:idx';

export const GET_COMMUNITY_INFO = '/front/community/main';
export const GET_COMMUNITY_DETAIL = '/front/community/:idx';
export const GET_COMMUNITY_MY_GOOD = '/front/community/myGood';
export const GET_COMMUNITY_MY_REPLY_GOOD = '/front/community/myReplyGood'
export const GET_COMMUNITY_MY_NEIGHBORS = '/front/community/myNeighbors';
export const GET_COMMUNITY_BLOCK_NEIGHBORS = '/front/community/myBlockNeighbors';
export const GET_COMMUNITY_MY_WRITE = '/front/community/myWrite';
export const PUT_COMMUNITY_DETAIL = '/front/community/update';
export const DELETE_COMMUNITY_DETAIL = '/front/community/:idx';
export const POST_COMMUNITY_COMPLAIN = '/front/community/complain';
export const POST_COMMUNITY_REPLY = '/front/community/reply';
export const POST_COMMUNITY_WRITE = '/front/community/write';
export const GET_COMMUNITY_COMPLAINLIST = '/front/community/complainList';
export const GET_COMMUNITY_LIKE = '/front/community/good/:idx';
export const GET_COMMUNITY_MISSING_LIST = '/front/community/searchList'; // 찾는중 목록
export const DELETE_COMMUNITY_REPLY = '/front/community/reply/:community_reply_idx';
export const GET_COMMUNITY_FORBIDDENLIST = '/adm/community/communityForbiddenList';
export const PUT_COMMUNITY_VIEW_CNT = '/front/community/:idx/:user_idx';

export const GET_NOTICE = '/front/notice/:user_idx';
export const GET_NOTICE_BOARD_LIST = '/front/notice/boardList';
export const GET_NOTICE_BOARD_DETAIL = '/front/notice/board/:board_idx';

export const GET_MEMBER_INTEREST_ANIMALLIST = '/front/member/animalList';
export const GET_MEMBER_INTEREST_AREALIST = '/front/member/areaList';
export const POST_MEMBER_PROFILE = '/front/member/profile';
export const GET_MEMBER_APPLE_LOGIN = '/front/member/appleleLogin';
export const GET_MEMBER_APPLE_RETURN = '/front/member/appleleReturn';
export const GET_MEMBER_GOOGLE_LOGIN = '/front/member/googleLogin';
export const GET_MEMBER_GOOGLE_RETURN = '/front/member/googleReturn';
export const GET_MEMBER_KAKAO_LOGIN = '/front/member/kakaoLogin';
export const GET_MEMBER_KAKAO_RETURN = '/front/member/kakaoReturn'
export const GET_MEMBER_NAVER_LOGIN = '/front/member/naverLogin';
export const GET_MEMBER_NAVER_RETURN = '/front/member/naverReturn';
export const POST_MEMBER_AGREE_TERMS = '/front/member/agreeTerms';
export const POST_MEMBER_INTEREST_ANIMAL = '/front/member/animal';
export const POST_MEMBER_INTEREST_AREA = '/front/member/area';
export const POST_MEMBER_NICKNAME = '/front/member/nickname';
export const POST_MEMBER_RECOMMENDER = '/front/member/recommender';
export const POST_MEMBER_MYAREA = '/front/member/myArea';
export const POST_MEMBER_NEIGHBORS = '/front/member/neighbors';
export const POST_MEMBER_BLOCK_NEIGHBORS = '/front/member/block';


export const GET_DIARY_LIST = '/front/dairy/main';
export const GET_MONTHLY_DIARY_LIST = '/front/dairy/month';
export const POST_DIARY = '/front/dairy/write';
export const PUT_DIARY = '/front/dairy/write';
export const DELETE_DIARY = '/front/dairy/:idx';
export const GET_MYPET_LIST = '/front/dairy/pet';
export const GET_LAST_DIARY = '/front/dairy/lastInfo';

export const GET_WALLET_LIST = '/front/wallet/main';
export const GET_MONTHLY_WALLET_LIST = '/front/wallet/month';
export const POST_WALLET = '/front/wallet/write';
export const PUT_WALLET = '/front/wallet/write';
export const DELETE_WALLET = '/front/wallet/:idx';
export const GET_WALLET_ASSET_LIST = '/front/wallet/asset';
export const GET_WALLET_CATEGORY_LIST = '/front/wallet/category';
export const GET_WALLET_STATISTIC = '/front/wallet/statistics';

export const POST_ASSET = '/front/setting/asset';
export const PUT_ASSET = '/front/setting/asset';
export const POST_BUDGET = '/front/setting/budget';
export const POST_WALLET_CATEGORY = '/front/setting/category';
export const PUT_WALLET_CATEGORY = '/front/setting/category';
export const GET_BACKUP_DIARY = '/front/setting/backup_diary';
export const GET_RESTORE_DIARY = '/front/setting/restore_diary';
export const GET_INIT_DIARY = '/front/setting/init_diary';

export const GET_RECOMMENT_CODE = '/front/setting/recommend';
export const GET_LOGOUT = '/front/setting/logout';
export const GET_WITHDRAW_REASON = '/front/setting/withdraw';
export const POST_WITHDRAW = '/front/setting/withdraw';
export const GET_SUBSCRIBE = '/front/setting/subscribe';
export const GET_UNSUBSCRIBE_REASON = '/front/setting/unsubscribe';
export const POST_UNSUBSCRIBE = '/front/setting/unsubscribe';
export const POST_COUPON = '/front/setting/coupon';

export const GET_PUSH_INFO = '/front/setting/pushInfo';
// 알림 시작
export const POST_ALARM_COMMENT = '/front/setting/comment';
export const POST_ALARM_DIARY = '/front/setting/dairy';
export const POST_ALARM_DIARY_CHANGED = '/front/setting/dairy_chaged';
export const POST_ALARM_DISTURB = '/front/setting/disturb';
export const POST_ALARM_EVENT = '/front/setting/event';
export const POST_ALARM_GOOD = '/front/setting/good';
export const POST_ALARM_INFORMATION = '/front/setting/information';
export const POST_ALARM_MISSING = '/front/setting/missing';
export const POST_ALARM_NEIGHBORS = '/front/setting/neighbors';
export const POST_ALARM_NOTIFICATION = '/front/setting/notification';
export const POST_ALARM_STAT = '/front/setting/statistics';
export const POST_ALARM_WALLET = '/front/setting/wallet';
export const POST_ALARM_WALLET_CHANGED = '/front/setting/wallet_changed';
// 알림 끝

export const POST_PET = '/front/setting/pet';
export const PUT_PET = '/front/setting/pet';
export const GET_SHARE_MEMBER_LIST = '/front/setting/share';
export const DELETE_SHARE_MEMBER = '/front/setting/share/:user_idx';
export const GET_SHARE_CODE = '/front/setting/shareCode';
export const POST_SHARE_CODE = '/front/setting/shareCode';



