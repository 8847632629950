export const URL_HOME = '/';
export const URL_LINK_CHECK = '/link/*';
export const URL_SPLASH = '/splash';
export const URL_JOIN = '/join';
export const URL_LOGIN = '/login';
export const URL_LOGIN_TEST = '/logintest';
export const URL_TOKEN = '/token';
export const URL_NOTICE = '/notice';
export const URL_PUSH_MAIN = '/push/main';
export const URL_NOTICE_BOARD_DETAIL = '/notice/board/:id'
export const URL_COMMUNITY = '/community';
export const URL_COMMUNITY_SEARCH = '/community/search';
export const URL_COMMUNITY_ADD = '/community/add';
export const URL_COMMUNITY_DETAIL = '/community/:id';
export const URL_MYHISTORY = '/myhistory';
export const URL_PROFILE = '/profile/:id'

export const URL_DIARY = '/note/diary';
export const URL_DIARY_ADD = '/note/diary/add';
export const URL_DIARY_MODIFY = '/note/diary/modify';
export const URL_WALLET = '/note/wallet';
export const URL_WALLET_ADD = '/note/wallet/add';
export const URL_WALLET_MODIFY = '/note/wallet/modify';
export const URL_WALLET_ANALYSIS = '/note/wallet/analysis';
export const URL_NOTE_SEARCH = '/note/search';
export const URL_NOTE_SETTING = '/note/setting';
export const URL_NOTE_SETTING_ANIMAL_ADD = '/note/setting/animal/add';
export const URL_NOTE_SETTING_ANIMAL_MODIFY = '/note/setting/animal/modify';
export const URL_NOTE_EXPEND_GROUP = '/note/setting/expend';
export const URL_NOTE_EXPEND_GROUP_ADD = '/note/setting/expend/add';
export const URL_NOTE_EXPEND_GROUP_MODIFY = '/note/setting/expend/modify';
export const URL_NOTE_SHARE_TAKE = '/note/setting/share/take';
export const URL_NOTE_SHARE_GIVE = '/note/setting/share/give';
export const URL_NOTE_MANAGE_MEMBER = '/note/setting/manage/member';
export const URL_NOTE_SETTING_ASSET = '/note/setting/asset';
export const URL_NOTE_SETTING_ASSET_ADD = '/note/setting/asset/add';
export const URL_NOTE_SETTING_ASSET_MODIFY = '/note/setting/asset/modify';
export const URL_NOTE_SETTING_BUDGET = '/note/setting/budget';
export const URL_NOTE_DATA_EXPORT = '/note/setting/data/export';
export const URL_NOTE_DATA_IMPORT = '/note/setting/data/import';
export const URL_NOTE_INITIAL = '/note/setting/initial';

export const URL_MORE = '/more';
export const URL_MORE_PROFILE = '/more/profile';
export const URL_MORE_PROFILE_SETTING_NICK = '/more/profile/setting/nick';
export const URL_MORE_ACCOUNT = '/more/account';
export const URL_MORE_WITHDRAW = '/more/account/withdraw';
export const URL_MORE_WITHDRAW1 = '/more/account/withdraw1';
export const URL_MORE_WITHDRAW2 = '/more/account/withdraw2';
export const URL_MORE_WITHDRAW3 = '/more/account/withdraw3';
export const URL_MORE_SUBSCRIPTION = '/more/subscription';
export const URL_MORE_SUBSCRIPTION_CANCEL = '/more/subscription/cancel';
export const URL_MORE_SUBSCRIPTION_CANCEL_STEP2 = '/more/subscription/cancel/step2';
export const URL_MORE_SETTING_AREA = '/more/setting/area';
export const URL_MORE_SETTING_ANIMAL = '/more/setting/animal';
export const URL_MORE_SETTING_NOTIFICATION = '/more/setting/notification';
export const URL_MORE_FAQ = '/more/faq';
export const URL_MORE_FAQ_DETAIL = '/more/faq/detail/:id';
export const URL_MORE_RULE_COMMUNITY = '/more/rule';
export const URL_MORE_COMPANY_INFO = '/more/company';
export const URL_MORE_COUPON = '/more/coupon';
export const URL_MORE_SERVICE_INFO = '/more/service'
export const URL_MORE_SERVICE_DETAIL_LOCATION = '/more/service/location'
export const URL_MORE_SERVICE_DETAIL_PERSONAL = '/more/service/personal'
export const URL_MORE_SERVICE_DETAIL_USE = '/more/service/use'
export const URL_MORE_SERVICE_DETAIL_MARKETING = '/more/service/marketing'

export const URL_MONTHLY = '/monthly'
export const URL_MONTHLY_DETAIL = '/monthly/:id'
export const URL_POPULAR = '/popular'
export const URL_POPULAR_DETAIL = '/popular/:id'

export const URL_SAMPLE = '/sample';
export const URL_SAMPLE_KZ = '/samplekz';
export const URL_SAMPLE_JEN = '/jen'
export const URL_SAMPLE_MODAL = '/modal'
export const URL_SAMPLE_SEARCH = '/search'
