import { CenterPopup, } from 'antd-mobile';

export function AlertModal({ show, label='', subtitle='', footer, children, onOk, onClose }) {

  const fnHandleMaskClick = () => {
    if (onClose) onClose();
  }

  return (
    <CenterPopup
      visible={show}
      onMaskClick={fnHandleMaskClick}
    >
      <div className="popup-list alert-modal">
        <div className="popup-wrap4">
            <div className="popup-title">
                <strong className="popup-title1">{label}</strong>
                <div className="reference">
                  <p className="reference-s2">{subtitle}</p>
                </div>
            </div>
            {children && <div className="popup-content">
                {children}
            </div>}
            <div className="popup-center-footer">
              {footer}
            </div>
        </div>
    </div>
    </CenterPopup>
  )
}