import { IconBack } from "components/assets/icon";
import { BaseLink } from "components/elements";
import { useNavigate, useNavigationType } from "react-router-dom";

export function BasePageHeader({ title, buttonArea=undefined, onButtonClick, className='', pageType='basic', children, to=null, state=null, onBack=undefined }) {
  const navigate = useNavigate();
  const navigationType = useNavigationType();

  const fnHandleClick = () => {
    if (onBack) {
      onBack();
    } else if (to) {
      navigate(to, (state? {...state} : {}))
    } else if (state && state?.from === 'link') {
      navigate(-2);
    } else {
      navigate(-1);
      //console.log(navigationType, window.location);
      //if (navigationType === 'REPLACE') navigate(-2);
      //else navigate(-1);
    }
  }

  return (
    <header className='fixed'>
      {
        pageType === 'basic' &&
        <div className={className ? `page-header ${className}` : 'page-header'}>
          <BaseLink to={to} state={state} onClick={fnHandleClick}><IconBack altText="뒤로가기"/></BaseLink>
          <h1 className="page-title">{title}</h1>
          {
            buttonArea &&
            <div className="header-icon">
              { buttonArea }
            </div>
          }
        </div>
      }
      {
        pageType === 'search' &&
        <div className="search-header">
            <BaseLink to={to} state={state} onClick={fnHandleClick}><IconBack altText="뒤로가기"/></BaseLink>
            {
              children
            }
        </div>
      }
      {
        pageType === 'join' &&
        <div className={className ? `page-header ${className}` : 'page-header join-header'}>
          <h1 className="page-title">{title}</h1>
          {
            buttonArea &&
            <div className="header-icon">
              { buttonArea }
            </div>
          }
        </div>
      }
      {/* {
        // faq - detail를 위한 임시 설정 (pageHeader false인 경우)
        !pageHeader &&
        <>
          <BaseLink to={to} onClick={fnHandleClick}><IconBack altText="뒤로가기"/></BaseLink>
          <h1 className="page-title">{title}</h1>
          {
            buttonArea
          }
        </>
      } */}
    </header>
  )
}