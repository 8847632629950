/**
 * @author kkazzong
 * @desc 커뮤니티 헤더
 */
import { COMMUNITY } from 'constants/index';
import { BaseHeaderAction } from './BaseHeaderAction';

export function BaseHeaderCommunity () {

  return (<>
      <div className={'layout-header-logo'}>
        <div className={'menu-title'}>{COMMUNITY}</div>
      </div>
      {/* <div className={'layout-header-nav-bar'}>
        <div className={'layout-header-nav-bar-box'}>
          <img src="/assets/images/icon-search.svg" alt="찾기" onClick={()=>{
          }}/>
        </div>
        <div className={'layout-header-nav-bar-box'}>
          <img src="/assets/images/icon-alarm-active.svg" alt="알람있음" />
        </div>
        <div className={'layout-header-nav-bar-box'}>
        <img src="/assets/images/icon-activity.svg" alt="나의활동내역" onClick={()=>{  }} /> 
        </div>        
      </div> */}
  </>
  );
};
