import { Navigate } from 'react-router-dom';
import { useAuth } from 'hooks/AuthContext';
import { URL_LOGIN } from 'routes/RouteUrl'
import { AGENT } from 'constants/Mobile';
import { $get, $getIOS } from 'utils/mobile';

function RequireAuth({ children, common }) {
  const AGENT_TYPE = common.agentType;
  const { user, login } = useAuth();

  if (!user) {
    if (AGENT_TYPE === AGENT.IOS_APP) {
      $getIOS('userToken', (userToken) => {
        $getIOS('userIdx', (userIdx) => {
          if (!userToken && !userIdx) return <Navigate to={URL_LOGIN} replace={true}/>;
          else login({ token: userToken, user_idx: userIdx });
        })
      })
    } else if (AGENT_TYPE === AGENT.ANDROID_APP) {
      const userToken = $get('userToken');
      const userIdx = $get('userIdx');
      if (!userToken && !userIdx) <Navigate to={URL_LOGIN} replace={true}/>;
      else login({ token: userToken, user_idx: userIdx });
    }
    else return <Navigate to={URL_LOGIN} replace={true}/>;
  }
  return children;
}

export default RequireAuth;