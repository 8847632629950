import { useRef, useEffect } from 'react';
import { MIN_Y, MAX_Y } from './BottomSheet';

const DIRECTION = {
  NONE: 'NONE',
  DOWN: 'DOWN',
  UP: 'UP',
};

export default function useBottomSheet() {
  const sheet = useRef(null);
  const content = useRef(null);

  const metrics = useRef({
    touchStart: {
      sheetY: 0,
      touchY: 0,
    },
    touchMove: {
      prevTouchY: 0,
      direction: DIRECTION.NONE,
    },
    isTouched: false, // contents 영역 터치 유무
  });

  useEffect(() => {
    const canUserMoveBottomSheet = () => {
      const { touchMove, isTouched } = metrics.current;

      if (!isTouched) return true;

      if (sheet?.current?.getBoundingClientRect().y !== MIN_Y) {
        return true;
      }

      if (touchMove.direction === DIRECTION.DOWN) {
        return content.current.scrollTop <= 0;
      }
      return false;
    };

    const handleTouchStart = (e) => {
      const { touchStart } = metrics.current;
      touchStart.sheetY = sheet.current.getBoundingClientRect().y;
      touchStart.touchY = e.touches[0].clientY;
    };

    const handleTouchMove = (e) => {
      const { touchStart, touchMove } = metrics.current;
      const currentTouch = e.touches[0];

      if (touchMove.prevTouchY === undefined) {
        touchMove.prevTouchY = touchStart.touchY;
      }

      if (touchMove.prevTouchY === 0) {
        // 맨 처음 앱 시작하고 시작시
        touchMove.prevTouchY = touchStart.touchY;
      }

      if (touchMove.prevTouchY < currentTouch.clientY) {
        touchMove.direction = DIRECTION.DOWN;
      } else if (touchMove.prevTouchY > currentTouch.clientY) {
        touchMove.direction = DIRECTION.UP;
      }

      if (canUserMoveBottomSheet()) {
        e.preventDefault();

        const touchOffset = currentTouch.clientY - touchStart.touchY;
        let nextSheetY = touchStart.sheetY + touchOffset;

        if (nextSheetY <= MIN_Y) {
          nextSheetY = MIN_Y;
        } else if (nextSheetY >= MAX_Y) {
          nextSheetY = MAX_Y;
        }

        sheet.current.style.setProperty(
          'transform',
          `translateY(${nextSheetY - MAX_Y}px)`,
        );
      } else {
        document.body.style.overflowY = 'hidden';
      }
    };

    const handleTouchEnd = (e) => {
      document.body.style.overflowY = 'auto';
      const { touchMove } = metrics.current;

      // Snap Animation
      const currentSheetY = sheet?.current?.getBoundingClientRect().y;

      if (currentSheetY !== MIN_Y) {
        if (touchMove.direction === DIRECTION.DOWN) {
          sheet.current.style.setProperty('transform', 'translateY(0)');
        }

        if (touchMove.direction === DIRECTION.UP) {
          sheet.current.style.setProperty(
            'transform',
            `translateY(${MIN_Y - MAX_Y}px)`,
          );
        }
      }

      // metrics 초기화
      metrics.current = {
        touchStart: {
          sheetY: 0,
          touchY: 0,
        },
        touchMove: {
          prevTouchY: 0,
          direction: DIRECTION.NONE,
        },
        isTouched: false,
      };
    };

    sheet.current.addEventListener('touchstart', handleTouchStart);
    sheet.current.addEventListener('touchmove', handleTouchMove);
    sheet.current.addEventListener('touchend', handleTouchEnd);
  }, []);

  useEffect(() => {
    const handleTouchStart = () => {
      // console.log('content handleTouchStart');
      metrics.current.isTouched = true;
    };
    content.current.addEventListener('touchstart', handleTouchStart);
  }, []);

  return { sheet, content };
}
