import { useContext } from "react";

const { createContext, useState } = require("react");

const CommonContext = createContext();

/*
* 공통 컨텍스트
(사용예시) 가계부 작성, 수정시 
지출분류, 자산 , 반복 모달 표시후 데이터 유지를 위해
가계부 컨택스트 만듦 @author kkazzong
*/
export const CommonProvider = ({ children }) => {

  const [sharedData, setSharedData] = useState({
    wallet: {}
  });

  const updateSharedData = ({key, newData}) => {
    setSharedData({ ...sharedData, [key]: {...newData} });
  };

  return (
    <CommonContext.Provider value={{ sharedData, updateSharedData }}>
      {children}
    </CommonContext.Provider>
  )
}

export const useCommonContext = () => { 
  return useContext(CommonContext);
}