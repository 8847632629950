import { getAreaListAPI } from 'api/member';
import { IconClose } from 'components/assets/icon';
import { BaseButton } from 'components/elements';
import { BaseIconButton, BaseTextButton } from 'components/elements/BaseButton';
import { CHECK, SELECTMYAREA } from 'constants';
import { useEffect, useState } from 'react';
import { BottomPopUp } from './BottomPopUp';

/*
initArea : {
  sido: '서울특별시',
  gugun: '금천구',
  code: '1234'
}

*/
/* getAreaCode : 지도에서 받아온 주소로 (ex. 서울특별시 금천구) area_code 를 (지역코드) 찾아내 set 해주는 함수 
    회원가입 & 프로필에서 지역 변경 시 지도에서 얻어온 주소 그대로 저장할 때 (모달을 열지 않고) 쓰임
*/
export function AreaPickerModal({show, initArea, onClose, onOk}) {

  // console.log(initArea)

  const [sidoList, setSidoList] = useState([]);
  const [gugunList, setGugunList] = useState([]);

  const [selectedArea, setSelectedArea] = useState({
    sido: null,
    gugun: null,
    code: null
  });

  const fnChoiceSido = (sido) => {
    // 시 선택시 구군 코드 초기화
    const { gugun, code, ...rest} = selectedArea;
    setSelectedArea({...rest, sido});
  }

  const fnChoiceGugun = (gugun, code) => {
    setSelectedArea({...selectedArea, gugun, code});
  }

  const fnHandleOk = () => {
    if (onOk) 
      onOk(selectedArea);
  }

  const fnHandleClose = () => {
    if (onClose) onClose();
  }

  const fnHandleMaskClick = () => {
    if (onClose) onClose();
  }

  const fnGetAreaList = async () => {
    await getAreaListAPI()
    .then(res => {
      // console.log(res);
      const { data_list } = res?.data
      const areaList = data_list?.reduce((acc, current) => {
        const key = current.area_sido;

        if (!acc[key]) {
          acc[key] = [];
        }

        acc[key].push(current);
        return acc;
      }, {});

      
      const sidos = Object.keys(areaList);
      const guguns = Object.values(areaList);
      setSidoList(sidos);
      setGugunList(guguns);
      if(!initArea?.sido) fnChoiceSido(sidos?.[0]); //초기값설정(선택한'시'없는경우)

    })
    .catch(err => {
      console.error(err);
    })
  }
  useEffect(() => {
    fnGetAreaList()
  }, []);
  useEffect(() => {
    if (initArea && initArea.sido && initArea.gugun) {
      setSelectedArea({...selectedArea, sido: initArea?.sido, gugun: initArea?.gugun, code: initArea?.code})
    } else {
      fnChoiceSido(sidoList?.[0]); //초기값설정(선택한'시'없는경우)
    }
  }, [initArea]);


  return ( 
    <BottomPopUp
      show={show}
      hasBottom={false}
      onMaskClick={fnHandleMaskClick}
    >
      <div className="popup-list area-picker-modal">
        <div className="popup-wrap">
          <div className="popup-title popup-close">
            <strong>{SELECTMYAREA}</strong>
            <BaseIconButton>
              <IconClose onClick={fnHandleClose} />
            </BaseIconButton>
          </div>
          <div className="popup-content">
            <div className="content-tab">
              <ul className="city" style={{ overflowY: 'scroll'}}>
                {sidoList.map((item, idx) => (
                  <li key={`sido_${idx}`}>
                    <BaseTextButton
                      className={selectedArea?.sido === item ? 'active' : ''}
                      onClick={() => fnChoiceSido(item)}
                      label={item}
                    />
                  </li>
                ))}
              </ul>

              <ul className="district"  style={{ overflowY: 'scroll', width: '60rem' }}>
                {gugunList.map((gugun) => (
                  gugun.map((item, idx) => (
                    item.area_sido === selectedArea.sido && (
                      <li key={`gugun_${idx}`}>
                        <BaseTextButton
                          className={selectedArea?.code === item?.area_code ? 'check' : ''}
                          onClick={() => fnChoiceGugun(item.area_gugun, item.area_code)}
                          label={item.area_gugun}
                        />
                      </li>
                    )                  
                  ))
                ))}
              </ul>
            </div>
          </div>
          <div className="button-wrap">
            <BaseButton 
              label={CHECK} 
              disabled={selectedArea?.code ? false : true}
              onClick={fnHandleOk} 
            />
          </div>
        </div>
      </div>
    </BottomPopUp>
  )
}

