import { useEffect, useState } from "react"
import { getRandomProfilePath } from "utils/utils"

export function ProfileImage({ className='',secondClassName='', size='', imageSrc='', onLoad }) {
  // 프로필 이미지 없는 경우 랜덤이미지 표시
  // profile-icon-{랜덤숫자}.svg
  const [randomSrc, setRandomSrc] = useState(1);

  const handleOnLoad = () => {
    if (onLoad) onLoad();
  }

  useEffect(() => {
    if (!imageSrc || imageSrc === '') {
      setRandomSrc(getRandomProfilePath())
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={className || `profile-wrap profile-img-${size}`}>
      <div className={secondClassName || 'profile-img-box'}>
          <img className="profile cover" src={imageSrc || randomSrc} alt="프로필 이미지"
          onLoad={handleOnLoad}/>
      </div>
    </div>
  )
}