import React from 'react';
import { useEffect, useRef, useState } from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet';
import 'react-spring-bottom-sheet/dist/style.css';
import { styled } from 'styled-components';

const BottomSheetWrapper = styled(BottomSheet)`
  /*  header handle 제거 */
  --rsbs-handle-bg: transparent;
  /*  header padding 제거 */
  [data-rsbs-header] {
    flex-shrink: 0;
    cursor: ns-resize;
    padding: 0px;
    // border-radius: 12px 12px 0px 0px;
  }
  /* header, content 사이 line 제거 */
  --rsbs-content-opacity: 0 !important;
  /* layout footer 보다 낮게 설정 */
  z-index: 1;
  /* 최대 크기 설정 */
  [data-rsbs-overlay] {
    max-width: 720px;
    margin: 0 auto;
    border-radius: 2.4rem 2.4rem 0 0;
    overflow: hidden;
  }
`;
export function SpringBottomSheet({
  header,
  isHeaderFix = true,
  content,
  position,
}) {
  const [open, setOpen] = useState(true);
  const [overlayVisible, setOverlayVisible] = useState(false); // 추가한거
  const [showHeader, setShowHeader] = useState(true);

  const sheetRef = useRef();
  const focusRef = useRef();

  useEffect(() => {
    // Setting focus is to aid keyboard and screen reader nav when activating this iframe
    // focusRef.current.focus();
  }, []);

  // const onDismiss = () => {
  //   setOpen(false);
  // };

  return (
    <>
    {overlayVisible && <div className="overlay fixed-layout" 
    style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0,0,0,0.5)' }}></div>} {/* 추가한거 */}
    <BottomSheetWrapper
      open={open}
      ref={sheetRef}
      initialFocusRef={focusRef}
      // onDismiss={onDismiss}
      blocking={false}
      scrollLocking={false}
      expandOnContentDrag={true}
      skipInitialTransition
      header={
        <div
          // style={{
          //   borderRadius: '12px 12px 0px 0px',
          // }}
        >
          {showHeader ? header : <></>}
        </div>
      }
      // footer={footer}
      defaultSnap={position}
      snapPoints={({ maxHeight }) => {
        let rat = 0.3;
        if (maxHeight >= 1850) {
          rat = 0.3;
        }

        if (maxHeight < 1850 && maxHeight >= 1600) {
          rat = 0.35;
        }

        if (maxHeight < 1600 && maxHeight >= 1400) {
          rat = 0.4;
        }

        if (maxHeight < 1400 && maxHeight >= 1250) {
          rat = 0.45;
        }

        if (maxHeight < 1250 && maxHeight >= 1130) {
          rat = 0.5;
        }

        if (maxHeight < 1130 && maxHeight >= 1030) {
          rat = 0.55;
        }

        if (maxHeight < 1030 && maxHeight >= 930) {
          rat = 0.6;
        }

        if (maxHeight < 930 && maxHeight >= 860) {
          rat = 0.65;
        }

        if (maxHeight < 860 && maxHeight >= 795) {
          rat = 0.7;
        }

        if (maxHeight < 795 && maxHeight >= 750) {
          rat = 0.75;
        }

        if (maxHeight < 750) {
          rat = 1;
        }

        return [maxHeight * rat, position];
      }}
      onSpringStart={() => {
        // console.log('Transition from:', sheetRef.current.height)
        requestAnimationFrame(() => {
          // console.log('Transition to:', sheetRef.current.height)
          // bottom sheet 가 position 위치일때는 보여준다.
          if (!isHeaderFix) {
            if (sheetRef.current.height === position) {
              setShowHeader(true);
              setOverlayVisible(false);
            } else {
              setShowHeader(false);
              setOverlayVisible(true);
            }
          }
        });
      }}
    >
      {content}
    </BottomSheetWrapper>
    </>
  );
}
