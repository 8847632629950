import { Map } from 'immutable';

export const TYPES = {
  POST_AUTH: 'POST_AUTH',
  POST_AUTH_SUCCESS: 'POST_AUTH_SUCCESS',

  SET_USER: 'SET_USER',
  SET_USER_LOGOUT: 'SET_USER_LOGOUT',
};

const initState = Map({
  auth: [],
  user: {}
});

export default function AuthReducer(state = initState, action) {
  switch (action.type) {
    case TYPES.POST_AUTH:
      return state.set('auth', action.auth);
    case TYPES.SET_USER:
        return state.set('user', action.user);
    case TYPES.SET_USER_LOGOUT:
        return state.set('user', {});

    default:
      return state;
  }
}

export const actions = {
  postAuth: ({ param }) => ({ type: TYPES.POST_AUTH, param }),
  setLoginSuccess: ({ user }) => ({ type: TYPES.SET_USER, user }),
  setLogoutSuccess: () => ({ type: TYPES.SET_USER_LOGOUT }),
};
