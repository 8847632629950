import { BaseLink, BaseTextButton } from 'components/elements';
import { BottomPopUp } from 'components/containers/BottomPopUp';
import { SELECTSUBJECT, CLOSE, CHECK } from 'constants';
import { useEffect, useState } from 'react';
import { BaseIconButton } from 'components/elements/BaseButton';
import { IconCloseSub } from 'components/assets/icon';
import { HELPWHAT } from 'constants';
import { COMMUNITY_CHANGE_CHECK_IDX, COMMUNITY_FINDING_IDX, COMMUNITY_FIND_IDX } from 'constants/Community';
import { useAuth } from 'hooks/AuthContext';
import { getMyWriteHistoryAPI } from 'api/community';
import { formatDate } from 'utils/utils';
import moment from 'moment';
import { AlertModal } from './AlertModal';
import { CANTWRITE } from 'constants';
import { EXPLAIN } from 'constants';

export const SubjectModal = ({ show, dataList, onOk, onClose, defaultData=null }) => { // multi 글작성할때는 1개선택 / 목록에서는 여러개선택
  const { user } = useAuth();
  const userIdx = Number(user?.user_idx);
  const [subjectList, setSubjectList] = useState([]);
  const [singleChoice, setSingleChoice] = useState({
    item: null,        // 선택한 상위 주제 정보
    disabled: true,    // 버튼 유효성 검사와 className 을 위한 state
    subItem: null,      // 선택한 하위 주제 정보
    modalClick: false, // 모달에서 선택했는지 (false : 커뮤니티상세에서 세팅 / true : 모달에서 직접 클릭)
  });
  const [openAlertModal, setOpenAlertModal] = useState(false);


  // 일주일 이내에 '찾는중' 카테고리 게시글을 작성했는지 확인하는 함수
  const fnCheckAlreadyWrite = (value) => {
    // 오늘
    const today = moment();
    // 게시글 작성한 날짜를 가져와서 변환
    const parsingDate = moment(value, 'YYYYMMDD');
    // 일주일 이내에 있는지 확인
    const oneWeekAgo = moment().subtract(7, 'days');
    // '[]'는 두 날짜를 포함하는 범위
    const isBetweenDate = parsingDate?.isBetween(oneWeekAgo, today, null, '[]'); 
    return isBetweenDate;
}


  // 확인 버튼 클릭 시
  const fnHandleOk = () => {
    if (onOk) {
      // console.log(singleChoice)
      if (userIdx && singleChoice?.subItem?.idx === COMMUNITY_FINDING_IDX) {
        const fnGetMyHistory = async () => {
          const params = {
            user_idx: userIdx
          };
          await getMyWriteHistoryAPI({ params })
            .then(res => {
              const { data_list } = res?.data;
              const filterWriteArr = data_list?.filter(item => item.category_idx === COMMUNITY_FINDING_IDX
                                                                && fnCheckAlreadyWrite(formatDate(item.create_date?.slice(0, 10), 'YYYYMMDD')));
              // 일주일 이내에 '찾는중' 카테고리 게시글을 작성한 경우
              if (data_list?.length > 0 && filterWriteArr?.length > 0) {
                setOpenAlertModal(true);
              // 아닌 경우
              } else {
                onOk(singleChoice?.subItem?.idx ? singleChoice?.subItem?.idx : singleChoice?.item?.idx);
              }
            })
            .catch(err => {
              console.error(err);
            });
        }
        fnGetMyHistory();
      } else {
        onOk(singleChoice?.subItem?.idx ? singleChoice?.subItem?.idx : singleChoice?.item?.idx);
      }
    }
  }


  // 닫기 버튼 클릭 시
  const fnCloseSubjectModal = () => {
    if (onClose)  {
      setSingleChoice({item: null, disabled: true, subItem: null});
      onClose();
    }
  }


  // 상위 주제 선택 시
  const fnClickItem = (item) => {
    if (item?.subList?.length === 0) {  // 하위 주제 없을 때
      setSingleChoice({item: item, disabled: false, subItem: null, modalClick: true});
    } else if (item?.subList?.length > 0) {   // 하위 주제 있을 때
      setSingleChoice({item: item, disabled: true, subItem: null, modalClick: true});
    }
  }

  
  // 하위 주제 선택 시 
  const fnClickSubItem = (item) => {
    setSingleChoice({...singleChoice, disabled: false, subItem: item, modalClick: true})
  }


  // 확인완료 제외하기 위한 코드
  useEffect(() => {
    if (dataList) {
      let filterArr = [];
      dataList?.forEach(item => {
        if (defaultData && defaultData === item.idx) {
          setSingleChoice({item: item, disabled: false, subItem: null})
        } 
        if (item.subList.length > 0) {
          item.subList.forEach(sub => {
            if (defaultData && defaultData === sub.idx) {
              setSingleChoice({item: item, disabled: true, subItem: sub})
            }
            if(sub.idx === COMMUNITY_CHANGE_CHECK_IDX) {
              filterArr.push(sub.idx)
            }
          })
        }
      })
      const filterList = dataList?.filter(item => {
        if (item?.subList?.length > 0) {
          item.subList = item.subList.filter(sub => !filterArr.includes(sub.idx));
        }
        return item;
      });
  
      setSubjectList(filterList);
    }
  }, [dataList, defaultData]);

  //24.08.01 mcb 요청에 의한 모달내 확인버튼 일괄 삭제
  useEffect(() => {
    if (singleChoice?.modalClick && singleChoice?.item) {
      if (singleChoice?.item?.subList?.length === 0) // 하위 카테고리 없는것
        fnHandleOk()
      else if (singleChoice?.item?.idx === 2 && singleChoice?.subItem) // 찾아주세요 - 하위카테고리 선택시
        fnHandleOk()
    }
  }, [singleChoice])


  const ListItem = ({ item, className, onClick }) => {
    return (
      <li>
        {/* {className} */}
        <BaseTextButton 
          className={className}
          onClick={onClick}
          label={item}
        />
      </li>
    );
  };

  //24.08.01 mcb 요청에 의한 모달내 확인버튼 일괄 삭제
  useEffect(() => {
    if (singleChoice?.modalClick && singleChoice?.item) {
      if (singleChoice?.item?.subList?.length === 0) // 하위 카테고리 없는것
        fnHandleOk()
      else if (singleChoice?.item?.idx === 2 && singleChoice?.subItem) // 찾아주세요 - 하위카테고리 선택시
        fnHandleOk()
    }
  }, [singleChoice])

  return (
    <>
    {
      openAlertModal ? (
        <AlertModal
          show={openAlertModal}
          label={CANTWRITE}
          subtitle={EXPLAIN}
          footer={
            <BaseTextButton
              className="point-g"
              label={CHECK}
              onClick={() => setOpenAlertModal(false)}
            />
          } 
        />
      ) : (
        <BottomPopUp 
          show={show}
          onMaskClick={fnCloseSubjectModal}
        >
          <div className="popup-bottom">
            <div className="popup-wrap subject-modal">
                <div className="popup-head popup-close">
                  <strong>{SELECTSUBJECT}</strong>
                  <BaseIconButton onClick={fnCloseSubjectModal}>
                    <IconCloseSub altText={CLOSE} />
                  </BaseIconButton>
                </div>
                <div className="popup-content">
                  <ul className="category">
                    {
                      subjectList?.map((item, idx) => (
                        <ListItem
                          key={`category_${idx}`}
                          item={item?.category_name}
                          className={singleChoice?.item?.idx === item.idx ? 'check' : ''}
                          onClick={() => fnClickItem(item)}
                        />
                      ))
                    }
                    {
                      // 선택한 상위 주제에 하위 주제가 있을 때
                      singleChoice?.item?.subList?.length > 0 &&
                      <>
                        <li className="help">
                          <BaseLink>{HELPWHAT}</BaseLink>
                        </li>
                        {
                          singleChoice?.item?.subList?.map((item, idx) => (
                            <ListItem
                              key={`categorySub_${idx}`}
                              item={item?.category_name}
                              className={singleChoice?.subItem?.idx === item.idx ? 'check' : ''}
                              onClick={() => fnClickSubItem(item)}
                            />
                          ))
                        }
                      </>
                    }
                  </ul>
                </div>
                {/* 24.08.01 mcb 요청에 의한 모달내 확인버튼 일괄 삭제 */}
                {/* 
                <div className="popup-button-wrap">
                  <BaseTextButton
                    label={CHECK}
                    className={!singleChoice?.disabled ? 'active' : 'disabled'}
                    disabled={singleChoice?.disabled}
                    onClick={fnHandleOk}
                  />
                </div> */}
            </div>
          </div>
        </BottomPopUp>
      )
    }

    
  </>
  );
};

