import { AutoCenter } from "antd-mobile"
import { BaseTextButton } from "components/elements"
import { REFRESH } from "constants"

export const NetworkError = () => {
  return (
    <div
        style={{
          width: '100%',
          height: '100%'
        }}  
      >
        <div
          style={{
            marginTop: 'calc(100% - 14rem)'
          }}
        >
          <div className="vm center">
            <div
              style={{
                padding: '12px 0px'
              }}
            >
              <p
                style={{
                  fontSize: '2rem',
                  textAlign: 'center',
                  color: '#606060',
                  lineHeight: '3rem',
                }}      
              >앗! 네트워크에 연결할 수 없어요.</p>
              <p
                style={{
                  fontSize: '1.6rem',
                  textAlign: 'center',
                  color: '#606060'
                }}
              >
                잠시 후 다시 시도해주세요.
              </p>
            </div>
            <div>
              <div className="popup-button-wrap">
                <BaseTextButton label={REFRESH} onClick={() => { window.location.reload() }}/>
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}