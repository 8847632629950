import { BaseInput } from "./BaseInput";

export function SwitchButton({ id, checked, onClick, onChange }) {

  const fnHandleClick = (e) => {
    if (onClick) onClick(e)
  }
  const fnHandleChange = (e) => {
    if (onChange) onChange(e)
  }

  return (
    <div className="toggle-wrap" onClick={fnHandleClick}>
      <BaseInput type="checkbox" id={id} checked={checked} className="tgl tgl-light" onChange={fnHandleChange}/>
      <label htmlFor={id} className="tgl-btn"></label>
    </div>
  )
}