import { useEffect, useState } from "react"
import { getUserInfoApi } from "api/common"
import { NOMEMBERPLAN } from "constants";
import { formatDate, convertDateStr, parseAddr } from "utils/utils";
import { BaseLink } from "components/elements";
import { URL_LOGIN, URL_MORE_SUBSCRIPTION } from "routes/RouteUrl";
import { BTN_SUBSCRIBE_PLAN } from "constants";
import { BTN_CHANGE_PLAN } from "constants";
import { INFO } from "constants";
import moment from "moment";
import { TRIAL_SINGLE_PLAN } from "constants";
import { useAuth } from './AuthContext';
import { useNavigate } from 'react-router-dom';

export const useMemberPlan = ({ userIdx }) => {
  const [memberPlan, setMemberPlan] = useState(null);
  const { logout } = useAuth();
  const navigate = useNavigate();

  const callMemberApi = () => {
    //getUserInfoApi({ userIdx: 44 })
    getUserInfoApi({ userIdx })
      .then(res => {
        const { data_list } = res.data;
        const data = data_list?.[0];

        //console.log(moment(data.single_date).add(1, 'months') > moment()  ? '안지남' : '지남')
        if (data) {
          //무료체험 이용권관련 (레벨2 최초 진입한 회원에게 1개월동안 주는 무료이용권 개념)
          //무료체험 이용권 사용중 결제한 경우 결제건이 우선되도록 (goods_idx가 0이면서)
          const trialYn = (data.goods_idx === 0  && (data.single_fg && (moment(data.single_date).add(1, 'months') > moment()))) ? true : false;
          //실결제 한사람
          const purchaseYn = data.goods_idx > 0 ? true : false;
          setMemberPlan({
            //멤버십관련
            planType: data.plan_type,
            planYn: purchaseYn || trialYn ? true : false, // 유무료구분 ( true: 유료 false: 무료)
            planTypeName: data.plan_type_name,
            planName: purchaseYn ? data.plan_name : trialYn ? TRIAL_SINGLE_PLAN :  null,
            planEndDate: purchaseYn ? data.end_date : trialYn ? moment(data.single_date).add(1, 'months') :  null,
            goodsIdx: data.goods_idx,
            goodsName: purchaseYn ? data.goods_name : trialYn ? TRIAL_SINGLE_PLAN :  null,
            ownerFg: data.owner_fg,
            //무료체험 이용권관련 (레벨2 최초 진입한 회원에게 1개월동안 주는 무료이용권 개념)
            //무료체험 이용권 사용중 결제한 경우 결제건이 우선되도록
            singlePlanYn : trialYn, 
            singleDate: data.single_date, // 무료체험 적용날짜 (1개월동안만 유효함)
            singleEndDate: moment(data.single_date).add(1, 'months'),
            singleFg: data.single_fg, // 1: 적용 0: 미적용 - 적용된 경우에 유료회원 취급함 (단, 광고는 보임)
            //회원정보관련
            status: data.status,
            backupDate: data.backup_date,
            budget: data.budget,
            userType: data.user_type,
            userEmail: data.user_email,
            userNickname: data.user_nickname,
            user_nickname: data.user_nickname,
            userProfileImgPath: data.profile_img_path,
            profile_img_path: data.profile_img_path,
            userLevel: data.user_level,
            user_level: data.user_level,
            userLevelName: data.user_level_name,
            user_level_name: data.user_level_name,
            visitCnt: data.visit_cnt,
            writeCnt: data.write_cnt,
            commentCnt: data.comment_cnt,
            neighborsCnt: data.neighbors_cnt,
            myAreaCode: data.my_area_code,
            myArea: {
              code: data.my_area_code,
              name: `${data.my_area_sido} ${data.my_area_gugun}`,
              sido: data.my_area_sido,
              gugun: data.my_area_gugun,
            },
            myAreaFullName: `${data.my_area_sido} ${data.my_area_gugun}`,
            interestAreaName1: data.interest_area_name1,
            interestAreaName2: data.interest_area_name2,
            myInterestArea: [
              { code: data.interest_area_code1, name: data.interest_area_name1, ...(parseAddr(data.interest_area_name1)) },
              { code: data.interest_area_code2, name: data.interest_area_name2, ...(parseAddr(data.interest_area_name2)) }
            ],
            interestAnimalName: data.interest_animal_name,
            interestAnimalCode: data.interest_animal_code,
            disturbModeFg: data.disturb_mode_fg,
            disturbModeStart: data.disturb_mode_start,
            disturbModeEnd: data.disturb_mode_end,
          })
        }
      })
      .catch(err => {
        console.error(err)

        if (err?.response?.data?.code === 401) {
          logout();
          navigate(URL_LOGIN);
        }
      })
  }
  useEffect(() => {
    if (userIdx) callMemberApi()
  }, [])

  // 수첩설정-지출분류, 수첩설정-자산설정, 수첩설정-구성원관리 메뉴에서 쓰임
  const PlanPanel = () => {
    if (memberPlan?.planYn) {
      // 유료
      return (
        <>  
          <div className="round-box-wrap64-line-active">
            <div className="text-group">
              <p>{memberPlan?.goodsName}</p>
              <p className="cph-black">{convertDateStr(formatDate(memberPlan?.planEndDate, 'YYYYMMDD'))}까지</p>
            </div>
          </div>
        </>
      )
    } else {
      // 무료
      return (
        <>
          <div className="round-box-wrap64-line-inactive">
          <div className="text-group">
            <p>{NOMEMBERPLAN}</p>
          </div>
          </div>
        </>
      )
    }
  }

  // 더보기 메뉴에서 쓰임, 위 컴포넌트와 나눈 이유는 html구조가 달라서...
  const PlanPanel2 = () => {
    if (memberPlan?.planYn) {
      // 유료
      return (
        <div className="info-box membership-has">
          <div className="text-box">
            <div>
              <p>{memberPlan?.goodsName}</p>
              <p>{convertDateStr(formatDate(memberPlan?.planEndDate, 'YYYYMMDD'))}까지</p>
            </div>
          </div>
          {
            // 둘이서멤버십만
            memberPlan?.goodsIdx === 2 &&
            <PlanDescInfo className="pt-8" desc={INFO}/>
          }
        </div>
      )
    } else {
      // 무료
      return (
        <div className="info-box membership-empty">
          <div className="text-box">
            <p>{NOMEMBERPLAN}</p>
          </div>
          <PlanDescInfo className="pt-8" desc={INFO}/>
          {/* <div className="subscription-link-group pt-8">
            <span className="point-gr"> 다양한 기능이 준비되어 있어요.
              <BaseLink className="point-g" to={URL_MORE_SUBSCRIPTION}>{BTN_SUBSCRIBE_PLAN}</BaseLink>
            </span>
          </div> */}
        </div>
      )
    }
  }

  const PlanDescInfo = ({ className='', desc, fixButtonLabel=undefined }) => {
    return (
      <div className={`subscription-link-group ${className}`}>
        <span className="point-gr">
          {desc}
          <BaseLink
            className="point-g"
            to={URL_MORE_SUBSCRIPTION}
          >
            {
              fixButtonLabel ? ( // 구독여부 상관없이 버튼 텍스트 fix를 위함 (예: 수첩설정- 데이터 저장하기 메뉴는 무조건 구독플랜확인 임)
                fixButtonLabel
              ) : (
                memberPlan?.planYn ? BTN_CHANGE_PLAN : BTN_SUBSCRIBE_PLAN
              )
            }
          </BaseLink>
          </span>
      </div>
    )
  }



  return { callMemberApi, memberPlan, PlanPanel, PlanPanel2, PlanDescInfo }
}
